import React from "react";

interface IResponsiveImgProps {
  url: string;
  alt?: string;
  maxHeight?: number | string;
}

export const ResponsiveImg: React.FC<IResponsiveImgProps> = ({
  url,
  alt,
  maxHeight = "100%",
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: maxHeight,
        overflow: "hidden",
      }}
    >
      <img
        src={url}
        alt={alt ?? ""}
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          objectFit: "contain",
        }}
      />
    </div>
  );
};
