import React, { useCallback, useEffect, useState } from "react";
import { IApprovalItem, IApprovalItemAsset } from "../services/thumbz-base-api";
import { getMimetype, Mimetype } from "../utils/get-mimetype";
import { PictureAsPdf } from "@mui/icons-material";
import { CardMedia, Avatar, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { ResponsiveImg } from "./responsive-img";

interface IRenderImgPreviewProps {
  approvalItemAsset: IApprovalItemAsset;
  showThumbnail?: boolean;
  height?: string | number;
}

export const RenderImgPreview: React.FC<IRenderImgPreviewProps> = ({
  approvalItemAsset,
  showThumbnail = true,
  height = 250,
}) => {
  const { ast_url, ast_mimetype, ast_original_filename } =
    approvalItemAsset.asset;
  const [thumbnail, setThumbnail] = useState<string | null>(null);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const mimeType = getMimetype(ast_mimetype);

  const captureThumbnail = (file: File) => {
    const videoBlobUrl = URL.createObjectURL(file);
    const video = document.createElement("video");
    video.src = videoBlobUrl;
    video.currentTime = 1; // Captura o frame no segundo 1 do vídeo

    video.onloadeddata = () => {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext("2d");
      context?.drawImage(video, 0, 0, canvas.width, canvas.height);
      const thumbnailUrl = canvas.toDataURL("image/png");
      setThumbnail(thumbnailUrl);
      URL.revokeObjectURL(videoBlobUrl); // Libera memória
    };
  };

  useEffect(() => {
    if (ast_url && mimeType === Mimetype.Video) {
      setThumbnail(ast_url);
      setVideoUrl(ast_url);
    }
    return () => {
      if (videoUrl) {
        URL.revokeObjectURL(videoUrl); // Libera a URL quando o componente é desmontado
      }
    };
  }, [ast_url, mimeType, videoUrl]);

  const renderMedia = useCallback(() => {
    let imageUrl = ast_url || "";

    switch (mimeType) {
      case Mimetype.Image:
        return (
          <ResponsiveImg
            url={imageUrl}
            alt={ast_original_filename ?? "Imagem"}
            maxHeight={height} // Defina a altura máxima para manter a imagem dentro da Box vermelha
          />
        );
      case Mimetype.Video:
        return (
          <>
            {!showThumbnail ? (
              <img
                src={thumbnail || imageUrl}
                style={{ width: "100%", cursor: "pointer" }}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: height,
                  overflow: "hidden",
                }}
              >
                <video
                  controls
                  src={imageUrl}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
            )}
          </>
        );
      case Mimetype.Pdf:
        return (
          <>
            {showThumbnail ? (
              <Avatar variant="square" style={{ width: "100%", height }}>
                <Stack
                  direction="column"
                  spacing={1}
                  alignItems="center"
                  justifyContent="center"
                >
                  <PictureAsPdf color="info" />
                  <Typography variant="caption" color="black">
                    {ast_original_filename ?? "PDF"}
                  </Typography>
                </Stack>
              </Avatar>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: height,
                  overflow: "hidden",
                }}
              >
                <iframe src={imageUrl} width="100%" height={"100%"} />
              </div>
            )}
          </>
        );
      default:
        return <p>Unsupported media type</p>;
    }
  }, [mimeType, ast_original_filename, ast_url, showThumbnail, thumbnail]);

  return renderMedia();
};
