// src/store.ts
import { configureStore } from "@reduxjs/toolkit";
import { approvalSlice } from "./features/approvals/approvalsSlice";

export const store = configureStore({
  reducer: {
    approval: approvalSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
