import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { fetchApproval } from "../features/approvals/approvalsSlice";
import { useParams } from "react-router-dom";
import { SplashScreen } from "../components/splash-screen";

interface ILoadingGuardProps {
  children: React.ReactNode;
}

export const LoadingGuard: React.FC<ILoadingGuardProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { asc_id, wrk_slug } = useParams<{
    wrk_slug: string;
    asc_id: string;
  }>();

  const { loading, approvalStageCustomer, error } = useAppSelector(
    (state) => state.approval
  );

  useEffect(() => {
    if (!asc_id || !wrk_slug) {
      alert("Parâmetros inválidos");
      return;
    }

    if (approvalStageCustomer === null) {
      dispatch(
        fetchApproval({
          asc_id: asc_id,
          wrk_slug: wrk_slug,
        })
      );
    }
  }, [dispatch]);

  if (approvalStageCustomer === null || loading) {
    return <SplashScreen />;
  }
  return <>{children}</>;
};
