import React from "react";
import { approvalSlice } from "../../features/approvals/approvalsSlice";
import { useDispatch } from "react-redux";
import { Typography, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { Close } from "@mui/icons-material";

interface IChatHeaderProps {}

export const ChatHeader: React.FC<IChatHeaderProps> = () => {
  const dispatch = useDispatch();

  function handleCloseChat() {
    dispatch(approvalSlice.actions.closeChat());
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: 1,
      }}
    >
      <Typography variant="h6">Histórico</Typography>
      <IconButton onClick={handleCloseChat}>
        <Close />
      </IconButton>
    </Box>
  );
};
