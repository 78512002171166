import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import React from "react";
import { Toaster } from "react-hot-toast";
import { LoadingGuard } from "../guards/loading";
import { MainLayout } from "./main-layout";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SettingsConsumer, SettingsProvider } from "../contexts/settings";
import Cookies from "js-cookie";
import type { Settings } from "../types/settings";
import { createTheme } from "../theme";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { StatusAllApprovedGuard } from "../guards/status-all-approved-guard";
import { StatusRejectedGuard } from "../guards/status-rejected-guard";

interface RootProps {
  children?: React.ReactNode;
}

const queryClient = new QueryClient();

const SETTINGS_STORAGE_KEY = "app.settings";

const resetSettings = (): void => {
  try {
    Cookies.remove(SETTINGS_STORAGE_KEY);
    window.location.reload();
  } catch (err) {
    console.error(err);
  }
};

const updateSettings = (settings: Settings): void => {
  try {
    Cookies.set(SETTINGS_STORAGE_KEY, JSON.stringify(settings));
    window.location.reload();
  } catch (err) {
    console.error(err);
  }
};

export const Root: React.FC<RootProps> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <SettingsProvider onReset={resetSettings} onUpdate={updateSettings}>
        <SettingsConsumer>
          {(_settings) => {
            const theme = createTheme({});

            return (
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <Toaster position="top-center" />
                <MainLayout>
                  <LoadingGuard>
                    <StatusRejectedGuard>
                      <StatusAllApprovedGuard>
                        {children}
                      </StatusAllApprovedGuard>
                    </StatusRejectedGuard>
                  </LoadingGuard>
                </MainLayout>
              </ThemeProvider>
            );
          }}
        </SettingsConsumer>
      </SettingsProvider>
    </QueryClientProvider>
  );
};
