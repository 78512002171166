import {
  Stack,
  Avatar,
  Typography,
  useMediaQuery,
  useTheme,
  Theme,
} from "@mui/material";
import { approvalSlice } from "../features/approvals/approvalsSlice";
import { useAppSelector } from "../hooks";

export const DesktopHeader: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { total } = useAppSelector(approvalSlice.selectors.selectCount);
  const approval = useAppSelector(approvalSlice.selectors.selectApproval);
  const organization = useAppSelector(
    approvalSlice.selectors.selectOrganization
  );

  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      spacing={2}
      alignItems="center"
    >
      <Avatar
        alt="Logo"
        src={organization?.org_photo.ast_url || ""}
        sx={{ width: 40, height: 40 }}
      />
      <Stack>
        <Typography variant="body2" component="div">
          {approval?.apv_name || "Aprovação"}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          Total: {total} itens
        </Typography>
      </Stack>
    </Stack>
  );
};
