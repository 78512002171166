import { LinearProgressProps, LinearProgress } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import React from "react";
import { approvalSlice } from "../features/approvals/approvalsSlice";
import { useAppSelector } from "../hooks";

interface IProgressProps {}

export const Progress: React.FC<IProgressProps> = () => {
  const { approved, rejected, alreadyRevised, total } = useAppSelector(
    approvalSlice.selectors.selectCount
  );

  const qty = (alreadyRevised / total) * 100;
  let color: LinearProgressProps["color"] = "primary";

  if (approved === total) {
    color = "success";
  } else if (rejected > 0) {
    color = "error";
  } else {
    color = "primary";
  }

  return (
    <LinearProgress
      variant="determinate"
      color={color}
      value={qty}
      sx={{ height: 8, borderRadius: 0 }}
    />
  );
};
