import { createBrowserRouter } from "react-router-dom";
import { Main } from "../pages";
import { Root } from "../layout/root";

export const router = createBrowserRouter([
  {
    path: "/:wrk_slug/:asc_id",
    element: (
      <Root>
        <Main />
      </Root>
    ),
  },
  // 404
  {
    path: "*",
    element: <h1>404</h1>,
  },
]);
