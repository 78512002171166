import { Box, Stack } from "@mui/system";
import React from "react";
import { ChatBubble } from "./chat-bubble";
import { useAppSelector } from "../../hooks";
import { approvalSlice } from "../../features/approvals/approvalsSlice";
import { Divider, Typography } from "@mui/material";
import { ChatMessage } from "./chat-message";

interface IChatBodyProps {}

export const ChatBody: React.FC<IChatBodyProps> = () => {
  const comments = useAppSelector(approvalSlice.selectors.revisionComments);
  const commentsCount = useAppSelector(
    approvalSlice.selectors.revisionComments
  );

  const ChatContent = () => {
    if (commentsCount.length === 0) {
      return (
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            justifyContent: "center",
            alignItems: "center",
            p: 3,
          }}
        >
          <Typography variant="body2" color="text.secondary">
            Nenhuma observação
          </Typography>
        </Box>
      );
    }

    return (
      <Stack spacing={2} sx={{ flex: "1 1 auto", overflowY: "auto", p: 3 }}>
        {comments.map((comment) => (
          <ChatBubble key={comment.aic_id} comment={comment} />
        ))}
      </Stack>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flex: "1 1 auto",
        flexDirection: "column",
        minHeight: 0,
      }}
    >
      <ChatContent />
      <Box my={1}>
        <Divider />
      </Box>
      <Box p={1}>
        <ChatMessage />
      </Box>
    </Box>
  );
};
