import { Send } from "@mui/icons-material";
import { Avatar, OutlinedInput, Tooltip, IconButton, Box } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { approvalSlice } from "../../features/approvals/approvalsSlice";
import toast from "react-hot-toast";
import { IApprovalItemCommentAicByEnum } from "../../services/thumbz-base-api";

interface IChatMessageProps {}

export const ChatMessage: React.FC<IChatMessageProps> = () => {
  const [localText, setlocalText] = useState<string>("");
  const actualRevision = useAppSelector(
    approvalSlice.selectors.selectedRevision
  );
  const customer = useAppSelector(approvalSlice.selectors.selectCustomer);
  const dispatch = useAppDispatch();

  function handleOnChange(comment: string) {
    setlocalText(comment);
  }

  function submitComment() {
    if (!actualRevision?.air_id) {
      toast.error("Selecione um item para comentar.");
      return;
    }

    if (!localText || localText === "") {
      toast.error("Digite um comentário.");
      return;
    }

    if (!customer) {
      toast.error("Selecione um cliente.");
      return;
    }

    dispatch(
      approvalSlice.actions.addComment({
        air_id: actualRevision?.air_id,
        comment: localText,
        aic_by: IApprovalItemCommentAicByEnum.Customer,
        aic_color: null,
        customer: customer,
      })
    );
    setlocalText("");
  }

  return (
    <Stack direction="row" spacing={2} justifyContent={"space-around"}>
      <Avatar src={customer?.cust_photo?.ast_url ?? ""} />
      <Stack flexGrow={1}>
        <OutlinedInput
          onChange={(e) => handleOnChange(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              submitComment();
            }
          }}
          size="small"
          value={localText}
          minRows={1}
          maxRows={10}
          multiline
          placeholder="Digite uma mensagem"
        />
      </Stack>
      <IconButton color="primary" disabled={!localText} onClick={submitComment}>
        <Send />
      </IconButton>
    </Stack>
  );
};
