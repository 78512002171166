import { useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useAppDispatch } from "../hooks";
import { approvalSlice } from "../features/approvals/approvalsSlice";

interface IMobileDetectorProps {}

export const MobileDetector: React.FC<IMobileDetectorProps> = () => {
  const theme = useTheme();
  const isMobilePhone = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(approvalSlice.actions.updateisMobile(isMobilePhone));
  }, [isMobilePhone]);

  return null;
};
