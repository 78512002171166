import { useTheme } from "@mui/material";
import React from "react";
import { Carousel, CarouselProps } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

interface ICustomCarouselProps extends Partial<CarouselProps> {}

export const CustomCarousel: React.FC<ICustomCarouselProps> = ({
  children,
  ...props
}) => {
  const theme = useTheme();
  // Função de renderização customizada para os indicadores (bolinhas)
  const renderIndicator = (
    clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void, // Adiciona o parâmetro de evento
    isSelected: boolean,
    index: number,
    label: string
  ) => {
    const indicatorStyle: React.CSSProperties = {
      width: 8,
      height: 8,
      margin: "0 8px", // Espaçamento entre as bolinhas
      backgroundColor: isSelected
        ? theme.palette.primary.main
        : theme.palette.grey[300],
      borderRadius: "50%",
      display: "inline-block",
      cursor: "pointer",
    };

    return (
      <li
        key={index}
        style={indicatorStyle}
        onClick={clickHandler} // O clickHandler agora recebe o evento de clique
        role="button"
        aria-label={`${label} ${index + 1}`}
      />
    );
  };

  return (
    <Carousel
      showArrows={true}
      infiniteLoop={false}
      showThumbs={false}
      showIndicators={true}
      showStatus={true}
      swipeable
      emulateTouch
      dynamicHeight={false}
      renderIndicator={renderIndicator}
      statusFormatter={(current, total) => `${current} de ${total}`}
      {...props}
    >
      {children}
    </Carousel>
  );
};
