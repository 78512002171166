import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import { useAppSelector } from "../hooks";
import { approvalSlice } from "../features/approvals/approvalsSlice";
import { ChatBody } from "./chat/chat-body";

const drawerWidth = 350; // Largura fixa do Drawer

// O Main agora vai ajustar a margem com base no estado "open"
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1, // Para que ele cresça conforme necessário
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: open ? 0 : `-${drawerWidth}px`, // O conteúdo é empurrado quando o Drawer está aberto
  width: open ? `calc(100% - ${drawerWidth}px)` : "100%", // Ajusta a largura quando o Drawer está aberto
}));

interface IPersistentDrawerRightProps {
  children: React.ReactNode;
}

const DesktopDrawer: React.FunctionComponent<IPersistentDrawerRightProps> = ({
  children,
}) => {
  const theme = useTheme();
  const chatOpen = useAppSelector(approvalSlice.selectors.isChatOpen);

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      {/* O Main ajusta a largura e a margem com base no estado do Drawer */}
      <Main open={chatOpen}>{children}</Main>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent" // Drawer persistente que não sobrepõe o conteúdo
        anchor="right" // Fixa o Drawer à direita
        open={chatOpen} // Estado de abertura do Drawer
      >
        <ChatBody />
      </Drawer>
    </Box>
  );
};

export default DesktopDrawer;
