import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { approvalSlice } from "../features/approvals/approvalsSlice";
import { useAppSelector, useAppDispatch } from "../hooks";
import { RevisionItem } from "./revision-item";
import { MobileHeader } from "./mobile-header";
import { RenderIcon } from "./icon-overlay";
import { useIcon } from "./approval-item-preview";
import { RenderImgPreview } from "./render-img-preview";
import { Box, Stack } from "@mui/system";
import { Finish } from "./finish";
import { IApprovalItemRevisionAirStatusEnum } from "../services/thumbz-base-api";

interface IMobileContainer {}

export const MobileContainer: React.FC<
  IMobileContainer
> = ({}: IMobileContainer) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const revisions = useAppSelector(approvalSlice.selectors.selectRevisions);
  const selectedRevision = useAppSelector(
    approvalSlice.selectors.selectedRevision
  );
  const [allItemsApproved, setAllItemsApproved] = useState(false);
  const [manualExpandControl, setManualExpandControl] = useState<string | null>(
    null
  ); // Controla a expansão manual do Accordion
  const accordionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  function handleSelectRevision(air_id: string) {
    setManualExpandControl((prev) => (prev === air_id ? null : air_id)); // Alterna o estado de expansão manualmente
    dispatch(approvalSlice.actions.selectRevision({ air_id }));
  }

  const scrollToAccordion = (air_id: string) => {
    const element = accordionRefs.current[air_id];
    const headerOffset = 80;
    const elementPosition = element?.getBoundingClientRect().top || 0;
    const offsetPosition = elementPosition + window.scrollY - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Atualiza o estado `allItemsApproved` se todos os itens estiverem aprovados ou solicitados
    const allApproved = revisions.every(
      (revision) =>
        revision.air_status === IApprovalItemRevisionAirStatusEnum.Approved ||
        revision.air_status === IApprovalItemRevisionAirStatusEnum.Requested
    );
    setAllItemsApproved(allApproved);

    // Selecione o próximo item pendente e faça o scroll até ele
    if (selectedRevision && accordionRefs.current[selectedRevision.air_id]) {
      scrollToAccordion(selectedRevision.air_id);
    }
  }, [selectedRevision, revisions]);

  return (
    <>
      <MobileHeader />

      <Box paddingTop="70px" paddingRight={0} paddingBottom={0} paddingLeft={0}>
        {revisions.map((revision, index) => {
          // Passa o air_id de cada revisão para o seletor isItemDisabled
          const isDisabled = useAppSelector((state) =>
            approvalSlice.selectors.isItemDisabled(state)(revision.air_id)
          );

          const selectorItemStatus = useAppSelector(
            approvalSlice.selectors.selectItemStatus
          )(revision.air_id);
          const icon = useIcon(selectorItemStatus);

          return (
            <Accordion
              key={revision.air_id}
              expanded={
                manualExpandControl === revision.air_id ||
                (!allItemsApproved &&
                  selectedRevision?.air_id === revision.air_id)
              } // Expande automaticamente apenas se ainda houver itens pendentes, ou respeita o controle manual
              onChange={() => handleSelectRevision(revision.air_id)}
              disabled={isDisabled} // Desativa o Accordion se o item estiver aprovado
              style={{
                backgroundColor: "transparent",
                border: "none",
                transitionDuration: "200ms",
              }}
              ref={(el) => (accordionRefs.current[revision.air_id] = el)}
            >
              <AccordionSummary
                expandIcon={isDisabled ? null : <ExpandMoreIcon />}
                aria-controls={`panel-${revision.air_id}-content`}
                id={`panel-${revision.air_id}-header`}
                style={{ backgroundColor: theme.palette.background.paper }}
              >
                {/* Layout de ícone, descrição e imagem com alinhamento flex */}
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    flex={1}
                    spacing={1}
                  >
                    <Stack>
                      <RenderImgPreview
                        height={25}
                        showThumbnail
                        approvalItemAsset={
                          revision.approvalitem.approvalItemAsset[0]
                        }
                      />
                    </Stack>

                    <Box maxWidth="200px">
                      <Typography
                        noWrap
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          textAlign: "left",
                        }}
                      >
                        Item {index + 1}
                      </Typography>
                    </Box>
                  </Stack>

                  <Box mr={1}>
                    <RenderIcon icon={icon} size={20} />
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <RevisionItem revision={revision} />
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>

      <Finish />
    </>
  );
};
