import { useMediaQuery, useTheme } from "@mui/material";
import { DesktopContainer } from "../components/desktop-container";
import DesktopDrawer from "../components/desktop-drawer";
import { MobileDrawer } from "../components/mobile-drawer";
import { MobileContainer } from "../components/mobile-container";

export const Main: React.FC = () => {
  const theme = useTheme();
  const isMobilePhone = useMediaQuery(theme.breakpoints.down("sm"));

  if (isMobilePhone) {
    return (
      <MobileDrawer>
        <MobileContainer />
      </MobileDrawer>
    );
  }

  return (
    <DesktopDrawer>
      <DesktopContainer />
    </DesktopDrawer>
  );
};

export default Main;
