import * as React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useAppSelector } from "../hooks";
import { approvalSlice } from "../features/approvals/approvalsSlice";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box"; // Para estilizar o fundo
import { ChatBody } from "./chat/chat-body";
import { ChatHeader } from "./chat/chat-header";
import { Divider } from "@mui/material";

interface IMobileDrawer {
  children: React.ReactNode;
}

export const MobileDrawer: React.FunctionComponent<IMobileDrawer> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const isChatOpen = useAppSelector(approvalSlice.selectors.isChatOpen);

  function handleOpenChat() {
    dispatch(approvalSlice.actions.openChat());
  }

  function handleCloseChat() {
    dispatch(approvalSlice.actions.closeChat());
  }

  return (
    <>
      {/* O fundo do aplicativo muda com base no estado do Drawer */}
      <Box
        sx={{
          filter: isChatOpen ? "blur(4px)" : "none", // Desfoque quando o drawer está aberto
          transition: "filter 0.3s ease", // Suaviza a transição do desfoque
        }}
      >
        {children}
      </Box>

      {/* Drawer na parte inferior */}
      <SwipeableDrawer
        anchor="bottom"
        open={isChatOpen}
        onClose={handleCloseChat}
        onOpen={handleOpenChat}
      >
        <ChatHeader />
        <Divider />
        <ChatBody />
      </SwipeableDrawer>
    </>
  );
};
