import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { useMediaQuery, useTheme } from "@mui/material";
import { useAppDispatch } from "../hooks";
import { MobileDetector } from "../components/mobile-detector";

interface MainLayoutProps {
  children?: ReactNode;
}

export const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const MainLayoutRoot = styled("div")(({ theme }) => ({}));

  return (
    <MainLayoutRoot>
      <MobileDetector />
      {/* <MainNavBar /> */}
      {/* <MainSidebar
        onClose={(): void => setIsSidebarOpen(false)}
        open={isSidebarOpen}
      /> */}
      {children}
      {/* <Footer /> */}
    </MainLayoutRoot>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};
