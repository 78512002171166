import {
  Box,
  Divider,
  Grid,
  Stack,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { approvalSlice } from "../features/approvals/approvalsSlice";
import { useAppSelector, useAppDispatch } from "../hooks";
import { ApprovalItemPreview } from "./approval-item-preview";
import { DesktopHeader } from "./desktop-header";
import { RevisionItem } from "./revision-item";
import { Finish } from "./finish";

interface IGridContainerProps {}

export const DesktopContainer: React.FC<
  IGridContainerProps
> = ({}: IGridContainerProps) => {
  const isChatOpen = useAppSelector(approvalSlice.selectors.isChatOpen);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isMobilePhone = useMediaQuery(theme.breakpoints.down("sm"));
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();

  const selectedRevision = useAppSelector(
    approvalSlice.selectors.selectedRevision
  );
  const revisions = useAppSelector(approvalSlice.selectors.selectRevisions);

  function handleSelectRevision(air_id: string) {
    dispatch(approvalSlice.actions.selectRevision({ air_id }));
  }

  return (
    <>
      <Grid
        container
        direction={isMobilePhone ? "column" : "row"}
        // height="100vh" // Garante que o Grid ocupe a altura total da tela
        overflow={"hidden"}
      >
        <Grid
          item
          order={isMobilePhone ? 1 : 0}
          xs={isChatOpen ? 3 : 3}
          md={isChatOpen ? 3 : 3}
          bgcolor={theme.palette.background.paper}
          borderRight={2}
          borderColor={theme.palette.divider}
        >
          <Box p={2}>
            <DesktopHeader />
          </Box>
          <Divider />
          {/* Defina a altura máxima e overflow apenas no conteúdo vermelho */}
          <Box height={"calc(100vh - 73px)"}>
            <Grid
              container
              style={{
                maxHeight: "100%", // Altura máxima do Grid
                overflowY: "auto", // Força a barra de rolagem a sempre aparecer
                scrollbarWidth: "auto", // Configura o estilo da barra no Firefox (auto para aparecer sempre)
              }}
              sx={{
                "&::-webkit-scrollbar": {
                  width: "8px", // Largura da barra de rolagem no Chrome/Safari
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: theme.palette.grey[300], // Cor da barra de rolagem
                  borderRadius: "4px", // Bordas arredondadas
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: theme.palette.grey[100], // Cor do fundo da barra de rolagem
                },
              }}
            >
              {[...revisions].map((revision, index) => (
                <Grid
                  key={`revision-${index}`}
                  item
                  md={isDesktop ? (isChatOpen ? 12 : 6) : 12}
                  lg={isDesktop ? 6 : 12}
                  p={1}
                >
                  <Box height={"100%"}>
                    <ApprovalItemPreview
                      onClick={() => handleSelectRevision(revision.air_id)}
                      revision={revision}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>

        {/* O outro Grid continua */}
        <Grid
          item
          order={isMobilePhone ? 0 : 1}
          xs={isChatOpen ? 9 : 9}
          md={isChatOpen ? 9 : 9}
          borderRight={1}
          borderColor={theme.palette.divider}
          py={4}
        >
          <Grid container height={"100%"}>
            <Grid
              item
              xs={12}
              sm={isChatOpen ? 1 : 1}
              md={isChatOpen ? 1 : 3}
              lg={isChatOpen ? 3 : 3}
              xl={isChatOpen ? 3 : 3}
            />
            <Grid
              item
              xs={12}
              sm={isChatOpen ? 10 : 10}
              md={isChatOpen ? 10 : 6}
              lg={isChatOpen ? 6 : 6}
              xl={isChatOpen ? 6 : 6}
              width={"100%"}
              height={"100%"}
              justifyContent={isDownMd ? "flex-start" : "center"}
              alignContent={isDownMd ? "flex-start" : "center"}
              alignItems={"center"}
              alignSelf={"center"}
              paddingTop={isDownMd ? 4 : 0}
            >
              {selectedRevision && (
                <Box>
                  <RevisionItem revision={selectedRevision} />
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Finish />
    </>
  );
};
