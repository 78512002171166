import { useCallback } from "react";
import { useTheme } from "@mui/material/styles";
import { ThemeColor } from "../theme/colors";
import { SettingsConsumer } from "../contexts/settings";
import { ColorPreset } from "../theme";
import { useNavigate } from "react-router-dom";

type ThemeIcons =
  | ThemeColor.AmericanYellow
  | ThemeColor.Gunmetal
  | ThemeColor.HalloweenOrange
  | ThemeColor.RustyRed;

interface LogoLightDarkMode {
  light: string;
  dark: string;
}

interface LogoOptions {
  only_name: Record<ThemeIcons, LogoLightDarkMode>;
  only_icon: Record<ThemeIcons, LogoLightDarkMode>;
  name_and_icon: Record<ThemeIcons, LogoLightDarkMode>;
}

const MAIN_PATH = "/assets/logo";
const NAME_PATH = `${MAIN_PATH}/only_name`;
const ICON_PATH = `${MAIN_PATH}/only_icon`;
const NAIC_PATH = `${MAIN_PATH}/name_and_icon/horizontal`;
const EXTENSION = "svg";

const LogoOptions: LogoOptions = {
  only_name: {
    [ThemeColor.AmericanYellow]: {
      light: `${NAME_PATH}/american-yellow.${EXTENSION}`,
      dark: `${NAME_PATH}/american-yellow.${EXTENSION}`,
    },
    [ThemeColor.Gunmetal]: {
      light: `${NAME_PATH}/gunmetal.${EXTENSION}`,
      dark: `${NAME_PATH}/5.${EXTENSION}`,
    },
    [ThemeColor.HalloweenOrange]: {
      light: `${NAME_PATH}/halloween-orange.${EXTENSION}`,
      dark: `${NAME_PATH}/halloween-orange.${EXTENSION}`,
    },
    [ThemeColor.RustyRed]: {
      light: `${NAME_PATH}/rusty-red.${EXTENSION}`,
      dark: `${NAME_PATH}/rusty-red.${EXTENSION}`,
    },
  },
  name_and_icon: {
    [ThemeColor.AmericanYellow]: {
      light: `${NAIC_PATH}/american-yellow.${EXTENSION}`,
      dark: `${NAIC_PATH}/american-yellow.${EXTENSION}`,
    },
    [ThemeColor.Gunmetal]: {
      light: `${NAIC_PATH}/gunmetal.${EXTENSION}`,
      dark: `${NAIC_PATH}/1.${EXTENSION}`,
    },
    [ThemeColor.HalloweenOrange]: {
      light: `${NAIC_PATH}/halloween-orange.${EXTENSION}`,
      dark: `${NAIC_PATH}/halloween-orange.${EXTENSION}`,
    },
    [ThemeColor.RustyRed]: {
      light: `${NAIC_PATH}/rusty-red.${EXTENSION}`,
      dark: `${NAIC_PATH}/rusty-red.${EXTENSION}`,
    },
  },
  only_icon: {
    [ThemeColor.AmericanYellow]: {
      light: `${ICON_PATH}/american-yellow.${EXTENSION}`,
      dark: `${ICON_PATH}/american-yellow.${EXTENSION}`,
    },
    [ThemeColor.Gunmetal]: {
      light: `${ICON_PATH}/gunmetal.${EXTENSION}`,
      dark: `${ICON_PATH}/5.${EXTENSION}`,
    },
    [ThemeColor.HalloweenOrange]: {
      light: `${ICON_PATH}/halloween-orange.${EXTENSION}`,
      dark: `${ICON_PATH}/halloween-orange.${EXTENSION}`,
    },
    [ThemeColor.RustyRed]: {
      light: `${ICON_PATH}/rusty-red.${EXTENSION}`,
      dark: `${ICON_PATH}/rusty-red.${EXTENSION}`,
    },
  },
};

interface ILogoInterface {
  variant?: keyof LogoOptions;
  width?: number;
  height?: number;
}

export const Logo: React.FC<ILogoInterface> = ({
  variant = "name_and_icon",
  width = undefined,
  height = undefined,
}) => {
  const router = useNavigate();
  const theme = useTheme();

  const getLogo = useCallback(
    (colorPreset: ColorPreset | undefined) => {
      if (!colorPreset) {
        return LogoOptions[variant][ThemeColor.AmericanYellow];
      }

      return LogoOptions[variant][colorPreset];
    },
    [variant]
  );

  return (
    <SettingsConsumer>
      {({ colorPreset }) => {
        const logo = getLogo(colorPreset)[theme.palette.mode];
        return (
          <a
            href="https://thumbz.com.br"
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: "inline-block", width: width, height: height }}
          >
            <img
              style={{ cursor: "pointer", width: "100%", height: "100%" }}
              src={logo}
              alt={`Logo ${colorPreset}`}
            />
          </a>
        );
      }}
    </SettingsConsumer>
  );
};
