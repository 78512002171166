export function getInitials(str: string): string {
  const trimmedStr = str.trim(); // Remove espaços no início e no final
  const words = trimmedStr.split(" ");
  let initials = "";

  if (words.length > 1 && words[1].length > 0) {
    // Se houver mais de uma palavra e a segunda palavra não for vazia, pega as iniciais
    initials = words[0][0] + words[1][0];
  } else {
    // Caso contrário, pega as duas primeiras letras da primeira palavra
    initials = words[0].slice(0, 2);
  }

  return initials.toUpperCase();
}
