/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface IApiUnauthorized {
  /**
   * HTTP Status
   * @default 401
   */
  statusCode: object;
  /**
   * message
   * @default "Unauthorized"
   */
  message: object;
}

export interface IApiAudit {
  /**
   * API ID
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  api_id: string;
  /**
   * The ID of the user who made the API request.
   * @format uuid
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  usu_id: string;
  /**
   * API Method
   * @example "GET"
   */
  api_method: string;
  /**
   * API URL
   * @example "/api/endpoint"
   */
  api_url: string;
  /**
   * The payload of the API request in JSON format.
   * @example "{"order": {"id": "3fa85f64-5717-4562-b3fc-2c963f66afa6", "items": [{"id": "1", "quantity": 2}]}}"
   */
  api_payload: string;
  /**
   * The date the audit record was created.
   * @format date-time
   */
  api_created_at: string;
  /**
   * The date the audit record was last updated.
   * @format date-time
   */
  api_updated_at: string;
  /** The date the audit record was deleted (soft). */
  api_deleted_at: object;
}

export interface IAuditListApiResponse {
  total: object;
  page: object;
  pages: object;
  size: object;
  data: IApiAudit[];
}

export interface IStatusType {
  /**
   * The unique ID of the status type
   * @format uuid
   */
  stp_id: string;
  /**
   * The label of the status type
   * @example "Not Started"
   */
  stp_label: string;
  /**
   * The description of the status type
   * @example "The task has not been started yet."
   */
  stp_description: string;
  /**
   * The type of the status
   * @uniqueItems true
   * @example "NOT_STARTED"
   */
  stp_type: IStatusTypeStpTypeEnum;
  /**
   * The order of the status type for sorting purposes
   * @example 1
   */
  stp_order: number;
  /** The statuses associated with this status type. */
  statuses: IStatus[];
}

export interface IStatus {
  /**
   * The unique ID of the status.
   * @format uuid
   */
  sts_id: string;
  /** The name of the status. */
  sts_name: string;
  /** The color associated with the status. */
  sts_color: string;
  /** The order of the status. */
  sts_order: number;
  /**
   * The date the status was created.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  sts_created_at: string;
  /**
   * The date the status was last updated.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  sts_updated_at: string;
  /**
   * The date the status was deleted (soft).
   * @example "2022-01-01T00:00:00Z"
   */
  sts_deleted_at: object;
  /** The status type associated with this status. */
  statusType: IStatusType;
}

export interface IWorkspaceStatusGroup {
  /**
   * The unique ID of the workspace status group.
   * @format uuid
   */
  wsg_id: string;
  /** The name of the status group. */
  wsg_name: string;
  /** The workspace this status group belongs to. */
  workspace: IWorkspace;
  /** The statuses that belong to this group. */
  statuses: IStatus[];
}

export interface IBucket {
  /**
   * The UUID of the bucket
   * @example "c6484454-cadb-4d18-9c8e-833a8a278191"
   */
  bkt_id: string;
  /** @example "public" */
  bkt_name: IBucketBktNameEnum;
  /** @example true */
  bkt_publicy: boolean;
  /** @example "A description of the bucket" */
  bkt_description: string;
  assets: IAsset[];
}

export interface IStripeProduct {
  /** The unique identifier of a stripe_product. */
  stp_id: string;
  /** The ID of the product item in Stripe. */
  stp_product_id: string;
  /** The ID of the pricing/plan item in Stripe. */
  stp_product_price_id: string;
  /** The chosen plan for the product. */
  stp_plan_type: IStripeProductStpPlanTypeEnum;
  /** The interval of the product. */
  stp_interval: string;
  /** Internal notes for the product. */
  stp_internal_notes: string;
  /** The product is active. */
  stp_is_active: boolean;
  /**
   * The date the product was created.
   * @format date-time
   */
  stp_created_at: string;
  /**
   * The date the product was last updated.
   * @format date-time
   */
  stp_updated_at: string;
  /** The date the product was deleted. */
  stp_deleted_at: object;
  /** List of billings associated with the stripe product. */
  billings: string[];
}

export interface IBilling {
  /** The unique identifier for a billing record. */
  blg_id: string;
  /** Indicates if the billing (subscription) is active or not. */
  blg_stripe_subscription_status: IBillingBlgStripeSubscriptionStatusEnum;
  /** The ID of the subscription price in Stripe. */
  blg_stripe_price_id: string;
  /** The ID of the subscription item in Stripe. */
  blg_stripe_subscription_id: string;
  /** The ID of the subscription item in Stripe. */
  blg_stripe_subscription_item_id: string;
  /** The UUID of the customer in Stripe. */
  blg_stripe_customer_id: string;
  /**
   * The date the billing record was created.
   * @format date-time
   */
  blg_created_at: string;
  /**
   * The date the billing record was last updated.
   * @format date-time
   */
  blg_updated_at: string;
  /** The date the billing record was deleted (soft). */
  blg_deleted_at: object;
  /** The user associated with the billing. */
  organization: IUser;
  /** The stripe product (subscription) associated with the billing. */
  stripeProduct: IStripeProduct;
}

export interface IRole {
  /** The unique ID of the role. */
  role_id: string;
  /**
   * The name of the role.
   * @example "Admin"
   */
  role_name: string;
  /**
   * A brief description of the role.
   * @example "Has access to all functionalities."
   */
  role_description: string;
  /**
   * The type of the role.
   * @example "OWNER"
   */
  role_constant: IRoleRoleConstantEnum;
  /**
   * The date the role was created.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  role_created_at: string;
  /**
   * The date the role was last updated.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  role_updated_at: string;
  /**
   * The date the role was deleted (soft).
   * @example "2022-01-01T00:00:00Z"
   */
  role_deleted_at: object;
}

export interface IOrganizationCollaborator {
  /** The UUID of the organization collaborator. */
  ocl_id: string;
  /**
   * The date the collaborators relationship with organization was created.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  ocl_created_at: string;
  /**
   * The date the collaborators relationship with organization was last updated.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  ocl_updated_at: string;
  /**
   * The date the collaborators relationship with organization was deleted (soft).
   * @example "2022-01-01T00:00:00Z"
   */
  ocl_deleted_at: object;
  /** The organization associated with a workspace. */
  organization: IOrganization;
  /** The user associated with a workspace. */
  user: IUser;
  /** The role of a user in a workspace. */
  role: IRole;
}

export interface ICountry {
  /**
   * Country ID
   * @example "ed899c98-ccab-49a5-a1b7-a5eeedf21ac0"
   */
  cty_id: string;
  /**
   * Country Name
   * @example "Afghanistan"
   */
  cty_name: string;
  /**
   * Country Alpha-2 Code
   * @example "AF"
   */
  cty_alpha_2: string;
  /**
   * Country Alpha-3 Code
   * @example "AFG"
   */
  cty_alpha_3: string;
  /**
   * Country Code
   * @example "004"
   */
  cty_country_code: string | null;
  /**
   * ISO 3166-2 Code
   * @example "ISO 3166-2:AF"
   */
  cty_iso_3166_2: string | null;
  /**
   * Region
   * @example "Asia"
   */
  cty_region: string | null;
  /**
   * Sub Region
   * @example "Southern Asia"
   */
  cty_sub_region: string | null;
  /**
   * Intermediate Region
   * @example ""
   */
  cty_intermediate_region: string | null;
  /**
   * Region Code
   * @example "142"
   */
  cty_region_code: string | null;
  /**
   * Sub Region Code
   * @example "034"
   */
  cty_sub_region_code: string | null;
  /**
   * Intermediate Region Code
   * @example ""
   */
  cty_intermediate_region_code: string | null;
  /**
   * The date the country was created.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  cty_created_at: string;
  /**
   * The date the country was last updated.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  cty_updated_at: string;
  /**
   * The date the country was deleted (soft).
   * @example "2022-01-01T00:00:00Z"
   */
  cty_deleted_at: object;
  /** The organizations associated with the country. */
  organizations: IOrganization[];
}

export interface IOrganizationInvitation {
  /**
   * The unique ID of the invitation.
   * @format uuid
   * @example "a1b2c3d4-e5f6-7g8h-9i0j-k1l2m3n4o5p6"
   */
  orgi_id: string;
  /**
   * The email address to which the invitation is sent.
   * @example "user@example.com"
   */
  orgi_email: string;
  /**
   * The unique code for the invitation.
   * @example "67100f8b54203c543c3aa28f92ced8b15634826069142b0e6c23c263a91eac49"
   */
  orgi_invitation_code: string;
  /**
   * Whether the invitation has been accepted.
   * @example false
   */
  orgi_accepted: boolean;
  /**
   * The date the organization invitation was created.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  orgi_created_at: string;
  /**
   * The date the organization invitation was last updated.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  orgi_updated_at: string;
  /**
   * The date the organization invitation was deleted (soft).
   * @example "2022-01-01T00:00:00Z"
   */
  orgi_deleted_at: object;
  organization: IOrganization;
}

export interface IUserPreference {
  /**
   * The user preferences ID
   * @example "90f1d0fd-8413-4492-b0c7-ea7baea76f6b"
   */
  usrp_id: string;
  /**
   * The date the userPreference was created.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  usrp_created_at: string;
  /**
   * The date the userPreference was last updated.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  usrp_updated_at: string;
  /**
   * The date the userPreference was deleted (soft).
   * @example "2022-01-01T00:00:00Z"
   */
  usrp_deleted_at: object;
  /** The user preference associated with the user. */
  user: IUser;
  /** List of activity audits associated with the organization. */
  favoriteOrganization: IOrganization[];
}

export interface IPersonalization {
  /**
   * The unique ID of the personalization.
   * @format uuid
   * @example "a1b2c3d4-e5f6-7g8h-9i0j-k1l2m3n4o5p6"
   */
  per_id: string;
  /**
   * The primary hex color of the personalization.
   * @example "#FFFFFF"
   */
  per_primary_hex_color: string | null;
  /**
   * The text hex color of the personalization.
   * @example "#000000"
   */
  per_text_hex_color: string | null;
  /**
   * URL of reference
   * @example "https://www.example.com"
   */
  per_url: string | null;
  /**
   * The date the personalization was created.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  per_created_at: string;
  /**
   * The date the personalization was last updated.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  per_updated_at: string;
  /**
   * The date the personalization was deleted (soft).
   * @example "2022-01-01T00:00:00Z"
   */
  per_deleted_at: object;
}

export interface IOrganization {
  /**
   * The unique identifier of the organization
   * @example "8c922366-8765-4412-8772-d93a5ff19a8d"
   */
  org_id: string;
  /**
   * The name of the organization
   * @example "GitHub"
   */
  org_name: string;
  /**
   * The onboarding metadata for the organization
   * @example {"user_id":"8c922366-8765-4412-8772-d93a5ff19a8d","organization_id":"8c922366-8765-4412-8772-d93a5ff19a8d","stepsCompleted":{"welcomeScreen":true,"profileSetup":true,"teamInvites":false,"workspaceSetup":false},"preferences":{"emailNotifications":true,"dashboardLayout":"default"},"onboardingDate":"2022-01-01T00:00:00Z"}
   */
  org_onboarding_metadata: object | null;
  /**
   * The date the organization was created.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  org_created_at: string;
  /**
   * The date the organization was last updated.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  org_updated_at: string;
  /**
   * The date the organization was deleted (soft).
   * @example "2022-01-01T00:00:00Z"
   */
  org_deleted_at: object;
  /** The billing associated with the collaborator. */
  billing: IBilling;
  /** The collaborators associated with the organization. */
  collaborators: IOrganizationCollaborator[];
  /** The country associated with the billing. */
  country: ICountry;
  /** The invitations for the workspace. */
  invitations: IOrganizationInvitation[];
  /** List of activity audits associated with the organization. */
  activityAudits: IActivityAudit[];
  /** The userPreference associated with the billing. */
  userPreference: IUserPreference;
  /** The personalization for the organization. */
  personalization: IPersonalization;
  /** The asset for the organization. */
  org_photo: IAsset;
}

export interface IApprovalItemComment {
  /**
   * Unique identifier of the approval item comment.
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  aic_id: string;
  /**
   * The comment for the item.
   * @example "Needs changes"
   */
  aic_comment: string;
  /**
   * The color of the message.
   * @example "primary"
   */
  aic_color?: IApprovalItemCommentAicColorEnum;
  /**
   * Indicates if the comment was made by a user or a customer.
   * @example "customer"
   */
  aic_by: IApprovalItemCommentAicByEnum;
  /** The user who made the comment, if applicable. */
  user: IUser | null;
  /** The customer who made the comment, if applicable. */
  customer: ICustomer | null;
  /** The revision this comment belongs to. */
  revision: IApprovalItemRevision;
  /**
   * The date the comment was created.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  aic_created_at: string;
  /**
   * The date the comment was last updated.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  aic_updated_at: string;
  /**
   * The date the comment was deleted (soft).
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  aic_deleted_at: string | null;
}

export interface IApprovalItemRevision {
  /**
   * Unique identifier of the approval item revision.
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  air_id: string;
  /** Comments associated with this approval. */
  air_comments?: IApprovalItemComment[];
  /**
   * The status of the approval (approved/rejected).
   * @example "approved"
   */
  air_status: IApprovalItemRevisionAirStatusEnum;
  /**
   * The date the revision request was made.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  air_request_date: string;
  /**
   * The date the revision was completed.
   * @format date-time
   * @example "2022-01-02T00:00:00Z"
   */
  air_completion_date: string | null;
  /** The stage-customer relationship this revision belongs to. */
  approvalStageCustomer: IApprovalStageCustomer;
  /** The item this revision belongs to. */
  approvalitem: IApprovalItem;
  /** The user who completed this revision. */
  completedBy: IUser;
  /**
   * The date the approval was created.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  air_created_at: string;
  /**
   * The date the approval was last updated.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  air_updated_at: string;
  /**
   * The date the approval was deleted (soft).
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  air_deleted_at: string | null;
}

export interface IApprovalItem {
  /**
   * The UUID of the approval item
   * @format uuid
   */
  item_id: string;
  /**
   * Description of the item.
   * @maxLength 255
   * @example "Item Description"
   */
  item_description: string | null;
  /**
   * The order of the item.
   * @example 1
   */
  item_order: number;
  /** The approval this item belongs to. */
  approval: IApproval;
  /** Assets associated with this item. */
  approvalItemAsset: IApprovalItemAsset[];
  /** Revisions associated with this item. */
  revisions: IApprovalItemRevision[];
  /**
   * The date the approval was created.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  item_created_at: string;
  /**
   * The date the approval was last updated.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  item_updated_at: string;
  /**
   * The date the approval was deleted (soft).
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  item_deleted_at: string | null;
}

export interface IApprovalItemAsset {
  /**
   * The UUID of the approval item asset order
   * @format uuid
   */
  aia_id: string;
  /**
   * The order of the asset
   * @example 1
   */
  aia_order: number;
  /** The approval item this asset order belongs to */
  approvalItem: IApprovalItem;
  /** The asset this order belongs to */
  asset: IAsset;
  /**
   * The date the approval was created.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  aia_created_at: string;
  /**
   * The date the approval was last updated.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  aia_updated_at: string;
  /**
   * The date the approval was deleted (soft).
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  aia_deleted_at: string | null;
}

export interface IAsset {
  /**
   * The UUID of the asset
   * @format uuid
   * @example "123e4567-e89b-12d3-a456-426655440000"
   */
  ast_id: string;
  /** @example "34937aedeec000b0c5dab76f968e9011" */
  ast_asset_id: string;
  /** @example 118381 */
  ast_bytes: number | null;
  /** @example "http://res.cloudinary.com/dtkxycprq/asset/upload/v1685499152/upload_knowledge/com_id/a8d1aa27-4db1-4bbc-b7d6-b1d515d31ece/contents/mf84bji0ce2nzj4jdihm.jpg" */
  ast_url: string | null;
  /** @example "upload_knowledge/com_id/a8d1aa27-4db1-4bbc-b7d6-b1d515d31ece/contents/mf84bji0ce2nzj4jdihm.jpg" */
  ast_path: string | null;
  /** @example "upload_knowledge/com_id/a8d1aa27-4db1-4bbc-b7d6-b1d515d31ece/contents/mf84bji0ce2nzj4jdihm.jpg" */
  ast_bucket: string | null;
  /**
   * The MIME type of the asset
   * @example "image/jpeg"
   */
  ast_mimetype: string;
  /** @example "X5" */
  ast_original_filename: string | null;
  /** The bucket this asset belongs to */
  bucket: IBucket;
  /** The organization this asset belongs to */
  organization: IOrganization;
  /** The workspace this asset belongs to */
  workspace: IWorkspace;
  /** The user this asset belongs to */
  user: IUser;
  /** Approval items associated with this asset order */
  assetOrders: IApprovalItemAsset[];
  /**
   * Date the asset was created
   * @format date-time
   */
  ast_created_at: string;
  /**
   * Date the asset was last updated
   * @format date-time
   */
  ast_updated_at: string;
  /**
   * Date the asset was deleted (if applicable)
   * @format date-time
   */
  ast_deleted_at: string;
}

export interface ICustomer {
  /**
   * Unique identifier of the customer.
   * @example "b26ad7bc-0e7c-47ae-8086-4995c5999a1f"
   */
  cust_id: string;
  /**
   * Name of the customer.
   * @maxLength 255
   * @example "John Doe"
   */
  cust_name: string;
  /**
   * Email of the customer.
   * @maxLength 255
   * @example "john.doe@example.com"
   */
  cust_email: string;
  /**
   * Mobile phone number of the customer.
   * @maxLength 20
   * @example "+1234567890"
   */
  cust_mobile_phone: string;
  /** Photo of the customer. */
  cust_photo: IAsset | null;
  /** The workspace this customer belongs to. */
  workspace: IWorkspace;
  /** Approval histories associated with this customer. */
  approvalHistories: IApprovalHistory[];
  /** Approval stages associated with this customer. */
  approvalStageCustomers: IApprovalStageCustomer[] | null;
  /** Approval flow stages associated with this customer. */
  approvalFlowStageCustomers: IApprovalStageCustomer[];
  /** Item revisions completed by this customer. */
  revisions: IApprovalItemRevision[];
  /** Approval item comments associated with this customer. */
  approvalItemComments: IApprovalItemComment[];
}

export interface IApprovalHistory {
  /**
   * Unique identifier of the approval history.
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  history_id: string;
  /**
   * The date the action was taken.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  action_date: string;
  /**
   * Action taken (approved/rejected).
   * @maxLength 50
   * @example "approved"
   */
  action: string;
  /** Comments for the action. */
  comments: string | null;
  /** The approval this history belongs to. */
  approval: IApproval;
  /** The customer who performed the action. */
  customer: ICustomer;
  /** The approval stage this history is associated with. */
  stage: IApprovalStage;
}

export interface IApprovalStage {
  /**
   * Unique identifier of the approval stage.
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  stg_id: string;
  /**
   * Order of the approval stage.
   * @example 1
   */
  stg_order: number;
  /**
   * Name of the stage.
   * @maxLength 255
   * @example "Initial Review"
   */
  stg_name: string;
  /** Detailed description of the stage. */
  stg_description: string | null;
  /**
   * Determines if the stage is completed.
   * @example false
   */
  stg_completed: boolean;
  /** Comments for the stage. */
  stg_comments: string | null;
  /** The approval this stage belongs to. */
  approval: IApproval;
  /** Customers responsible for this stage. */
  approvalStageCustomers: IApprovalStageCustomer[];
  /** Approval histories for this stage. */
  approvalHistories: IApprovalHistory[];
  /**
   * The date the role was created.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  stg_created_at: string;
  /**
   * The date the role was last updated.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  stg_updated_at: string;
  /**
   * The date the role was deleted (soft).
   * @example "2022-01-01T00:00:00Z"
   */
  stg_deleted_at: object;
}

export interface IApprovalStageCustomer {
  /** The unique identifier of the approval stage */
  asc_id: string;
  /** Approval status of the customer */
  asc_approved: boolean;
  /** The approval stage this record belongs to */
  approvalStage: IApprovalStage;
  /** The approval flow stage this record belongs to */
  approvalFlowStage: IApprovalFlowStage;
  /** The customer associated with this approval stage */
  customer: ICustomer;
  /** The revisions associated with this stage-customer. */
  revisions: IApprovalItemRevision[];
}

export interface IApprovalFlowStage {
  /**
   * Unique identifier of the approval flow stage.
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  afs_id: string;
  /**
   * Order of the approval stage.
   * @example 1
   */
  afs_order: number;
  /**
   * Name of the stage.
   * @maxLength 255
   * @example "Initial Review"
   */
  afs_name: string;
  /** Detailed description of the stage. */
  afs_description: string | null;
  /** The approval flow this stage belongs to. */
  approvalFlow: IApprovalFlow;
  /**
   * Customers responsible for this stage.
   * @default []
   */
  approvalStageCustomers: IApprovalStageCustomer[];
}

export interface IApprovalFlow {
  /**
   * Unique identifier of the approval flow.
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  flow_id: string;
  /**
   * Name of the approval flow.
   * @maxLength 255
   * @example "Standard Approval Process"
   */
  flow_name: string;
  /** Detailed description of the approval flow. */
  flow_description: string | null;
  /**
   * Indicates if the approval flow has been modified (different from the default).
   * @default false
   */
  flow_modified: boolean;
  /** The workspace this approval flow belongs to. */
  workspace: IWorkspace;
  /** Stages included in this approval flow. */
  stages: IApprovalFlowStage[];
  /**
   * The date the role was created.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  flow_created_at: string;
  /**
   * The date the role was last updated.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  flow_updated_at: string;
  /**
   * The date the role was deleted (soft).
   * @example "2022-01-01T00:00:00Z"
   */
  flow_deleted_at: object;
}

export interface IApproval {
  /**
   * Unique identifier of the approval.
   * @format uuid
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  apv_id: string;
  /**
   * Name of the approval.
   * @maxLength 255
   * @example "Project Approval"
   */
  apv_name: string;
  /** Status of the approval. */
  apv_status: IStatus;
  /** The workspace this approval belongs to. */
  workspace: IWorkspace;
  /** The approval flow used for this approval. */
  approvalFlow: IApprovalFlow;
  /** Stages of this approval. */
  stages: IApprovalStage[];
  /** Approval histories of this approval. */
  approvalHistories: IApprovalHistory[];
  /** Items contained in this approval. */
  approvalItems: IApprovalItem[];
  /**
   * The date the approval was created.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  apv_created_at: string;
  /**
   * The date the approval was last updated.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  apv_updated_at: string;
  /**
   * The date the approval was deleted (soft).
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  apv_deleted_at: string | null;
}

export interface IWorkspaceValidationCounter {
  /**
   * The unique ID of the workspace validation counter.
   * @format uuid
   * @example "f2bdec4c-ddf9-41cc-9570-9e26f107cd4f"
   */
  pvc_id: string;
  /**
   * The month and year of the validation count.
   * @example "2022-01"
   */
  pvc_year_month: string;
  /**
   * The number of validations that have been done.
   * @example 0
   */
  pvc_counter: number;
  /**
   * The date the counter was created.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  pvc_created_at: string;
  /**
   * The date the counter was last updated.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  pvc_updated_at: string;
  /**
   * The date the counter was deleted (soft).
   * @example "2022-01-01T00:00:00Z"
   */
  pvc_deleted_at: object;
  /** The workspace that this validation counter belongs to. */
  workspace: IWorkspace;
}

export interface IWorkspace {
  /**
   * The unique ID of the workspace.
   * @format uuid
   * @example "a1b2c3d4-e5f6-7g8h-9i0j-k1l2m3n4o5p6"
   */
  wrk_id: string;
  /**
   * The name of the workspace.
   * @maxLength 255
   * @example "My Awesome Workspace"
   */
  wrk_name: string;
  /**
   * The slug of the workspace.
   * @maxLength 255
   * @example "my-awesome-workspace"
   */
  wrk_slug: string;
  /**
   * A detailed description of the workspace.
   * @maxLength 255
   * @example "This workspace is about building a comprehensive platform for users."
   */
  wrk_description: string | null;
  /**
   * The date the workspace was created.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  wrk_created_at: string;
  /**
   * The date the workspace was last updated.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  wrk_updated_at: string;
  /**
   * The date the workspace was deleted (soft).
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  wrk_deleted_at: string;
  /** The status groups associated with this workspace. */
  statusGroups: IWorkspaceStatusGroup[];
  /** List of activity audits associated with the workspace. */
  activityAudits: IActivityAudit[];
  /**
   * The approvals of the workspace.
   * @example []
   */
  approvals: IApproval[];
  /** The validation counters for this workspace. */
  validationCounters: IWorkspaceValidationCounter[];
  /** The organization this workspace belongs to. */
  organization: IOrganization;
  /** The personalization of the workspace. */
  personalization: IPersonalization | null;
  /** The asset for the organization. */
  wrk_photo: IAsset | null;
  /** The approval flows for this workspace. */
  approvalFlows: IApprovalFlow[];
  /** The customers associated with this workspace. */
  customers: ICustomer[];
}

export interface IActivityAudit {
  /**
   * The activity audit UUID
   * @example "0a60a935-442b-43c7-884b-0c76368d342c"
   */
  act_id: string;
  /**
   * The message associated with the activity.
   * @example "User created a new workspace."
   */
  act_message: string;
  /**
   * The error message associated with the activity.
   * @example "Failed to create a new workspace."
   */
  act_error: string;
  /**
   * The date the audit was created.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  act_created_at: string;
  /**
   * The date the audit was last updated.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  act_updated_at: string;
  /**
   * The date the audit was deleted (soft).
   * @example "2022-01-01T00:00:00Z"
   */
  act_deleted_at: object;
  /** The user associated with the activity. */
  user: IUser;
  /** The workspace associated with the activity. */
  workspace: IWorkspace;
  /** The organization associated with the activity. */
  organization: IOrganization;
}

export interface ILoginAudit {
  /**
   * The user id (usu_id) associated with the login.
   * @example "35680c0b-47d7-4076-ae26-73013125d7ea"
   */
  lga_id: string;
  /**
   * The linked provider ID (for example, "google.com" for the Google provider).
   * @example "google.com"
   */
  lga_type: string;
  /**
   * The ip address of the login.
   * @example "95.130.17.84"
   */
  lga_ip_address: string;
  /**
   * The client of the login.
   * @example "Chrome, Mac OS 10.15.7"
   */
  lga_client: string;
  /**
   * The date the audit was created.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  lga_created_at: string;
  /**
   * The date the audit was last updated.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  lga_updated_at: string;
  /**
   * The date the audit was deleted (soft).
   * @example "2022-01-01T00:00:00Z"
   */
  lga_deleted_at: object;
  /** The user associated with the login. */
  user: IUser;
}

export interface IUser {
  /** The unique identifier of a user. */
  usu_id: string;
  /** Firebase UID of the user. */
  usu_external_uid: string | null;
  /** Name of the user. */
  usu_name: string | null;
  /** Email of the user. */
  usu_email: string | null;
  /**
   * The date the users was created.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  usu_created_at: string;
  /**
   * The date the users was last updated.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  usu_updated_at: string;
  /**
   * The date the users was deleted (soft).
   * @example "2022-01-01T00:00:00Z"
   */
  usu_deleted_at: object;
  /** List of activity audits associated with the user. */
  activityAudits: IActivityAudit[];
  /** List of login audits associated with the user. */
  loginAudits: ILoginAudit[];
  /**
   * User config
   * @example {"bb1aaa45-3e9e-40d0-9a1f-0f4848bec21d":{"value":"true"},"b727ccb4-969d-4ab0-9306-c787e80fb308":{"value":"false"}}
   */
  config: object;
  /** List of workspaces associated with the user. */
  collaborators: IOrganizationCollaborator[];
  /** The user preference associated with the user. */
  userPreference: IUser;
  /** The asset for the organization. */
  usu_photo: IAsset;
}

export interface IAuditListActivitiesResponseDto {
  /**
   * The activity audit UUID
   * @example "0a60a935-442b-43c7-884b-0c76368d342c"
   */
  act_id: string;
  /**
   * The message associated with the activity.
   * @example "User created a new workspace."
   */
  act_message: string;
  /**
   * The error message associated with the activity.
   * @example "Failed to create a new workspace."
   */
  act_error: string;
  /**
   * The date the audit was created.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  act_created_at: string;
  /**
   * The date the audit was last updated.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  act_updated_at: string;
  /**
   * The date the audit was deleted (soft).
   * @example "2022-01-01T00:00:00Z"
   */
  act_deleted_at: object;
  /** The user associated with the activity. */
  user: IUser;
  /** The workspace associated with the activity. */
  workspace: IWorkspace;
  /** The organization associated with the activity. */
  organization: IOrganization;
  /**
   * A message describing the activity.
   * @example "The user created a new workspace."
   */
  message: string;
}

export interface IAuditListActivitiesResponse {
  total: object;
  page: object;
  pages: object;
  size: object;
  data: IAuditListActivitiesResponseDto[];
}

export interface IOmitTypeClass {
  /** The unique identifier of a Twilio audit. */
  twl_id: string;
  /** User ID associated with the Twilio audit. */
  usu_id: string | null;
  /** Recipient of the Twilio message. */
  twl_to: string;
  /** Data sent in the Twilio message. */
  twl_data_send: string | null;
  /** Data received from the Twilio message. */
  twl_data_received: string | null;
  /** Error message, if any, from the Twilio message. */
  twl_error: string | null;
  /**
   * The date the twillio audit was created.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  twl_created_at: string;
  /**
   * The date the twillio audit was last updated.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  twl_updated_at: string;
  /**
   * The date the twillio audit was deleted (soft).
   * @example "2022-01-01T00:00:00Z"
   */
  twl_deleted_at: object;
}

export interface IAuditListTwilioResponse {
  total: object;
  page: object;
  pages: object;
  size: object;
  data: IOmitTypeClass[];
}

export interface IAuditListLoginResponse {
  total: object;
  page: object;
  pages: object;
  size: object;
  data: ILoginAudit[];
}

export interface IAuthDto {
  /**
   * The Firebase idToken provided by front-end integration
   * @format uid
   * @example "eyJhbGciOiJSUzI1NiIsImtpZCI6InRCME0yQSJ9.eyJpc3MiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGUuY29tLyIsImF1ZCI6ImdyaXR6a28tZDYzZjAiLCJpYXQiOjE2ODM0NjQwNzUsImV4cCI6MTY4NDY3MzY3NSwidXNlcl9pZCI6ImZiNHlmbXhyVnlaSzUxYkFLek55OXphbGFkQzIiLCJlbWFpbCI6ImNsaWVudGVAcGVuc2VhcHAuY29tLmJyIiwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIiwidmVyaWZpZWQiOmZhbHNlfQ.PAmIT2IYJQr69KQ2Nf9SU48CtEFhCfO2xn-XOeCB-CFyTIt5XfoaHDNHkwLm8oQH2QqyAR3wcve1QPMipzKEc6VaWYjPTrY6FVCRGk-IyGggxIhkGNRLeFpywXp-EH2rGk7bETf3bUS8yzvXvBQZ_UdjayCO8xzUhnqix_Y5sYHtCj-Qgf3EMmyR3wyE8nJHPxy-tm5y0nP65psN1wzTeSClRmcsBB8P-myOkPMG0mylJWxqdtH1D32cwBRlexvwi0MgFeF0miWaCrMb3mYyM9umATtJg4YByeJt1qzynFUp1EzQY0dlG2A_ag0DPuOhtpIbfFXFiEfEEy7eZcreIA"
   */
  firebase_idToken: string;
}

export interface IAuthLoginResponse {
  /**
   * The JWT token to be used in the Authorization header.
   * @format jwt
   */
  token: string;
  /** The user object. */
  user: IUser;
  /** The user favorite organization. If the user does not have a favorite organization, this field will be null. */
  favorite_organization: IOrganization;
  /** All organizations that user belongs to */
  user_organizations: IOrganization[];
}

export interface IAuthRefreshTokenRequestDto {
  /**
   * The refresh token provided by front-end integration
   * @example "NmAYfTsztz_iBeylxmiUJg"
   */
  refresh_token: string;
}

export type IUserAppMetadataDto = object;

export type IUserMetadataDto = object;

export type IUserIdentityDto = object;

export type IFactorDto = object;

export interface ISupabaseUserDto {
  /**
   * User ID
   * @example "user_id_example"
   */
  id: string;
  /** Application specific metadata */
  app_metadata: IUserAppMetadataDto;
  /** User specific metadata */
  user_metadata: IUserMetadataDto;
  /**
   * Audience
   * @example "aud_example"
   */
  aud: string;
  /**
   * Confirmation sent at timestamp
   * @example "2023-01-01T00:00:00Z"
   */
  confirmation_sent_at?: string | null;
  /**
   * Recovery sent at timestamp
   * @example "2023-01-01T00:00:00Z"
   */
  recovery_sent_at?: string | null;
  /**
   * Email change sent at timestamp
   * @example "2023-01-01T00:00:00Z"
   */
  email_change_sent_at?: string | null;
  /**
   * New email
   * @example "new_email@example.com"
   */
  new_email?: string | null;
  /**
   * New phone
   * @example "+1234567890"
   */
  new_phone?: string | null;
  /**
   * Invited at timestamp
   * @example "2023-01-01T00:00:00Z"
   */
  invited_at?: string | null;
  /**
   * Action link
   * @example "https://example.com/action"
   */
  action_link?: string | null;
  /**
   * Email
   * @example "email@example.com"
   */
  email?: string | null;
  /**
   * Phone
   * @example "+1234567890"
   */
  phone?: string | null;
  /**
   * Created at timestamp
   * @example "2023-01-01T00:00:00Z"
   */
  created_at: string;
  /**
   * Confirmed at timestamp
   * @example "2023-01-01T00:00:00Z"
   */
  confirmed_at?: string | null;
  /**
   * Email confirmed at timestamp
   * @example "2023-01-01T00:00:00Z"
   */
  email_confirmed_at?: string | null;
  /**
   * Phone confirmed at timestamp
   * @example "2023-01-01T00:00:00Z"
   */
  phone_confirmed_at?: string | null;
  /**
   * Last sign in at timestamp
   * @example "2023-01-01T00:00:00Z"
   */
  last_sign_in_at?: string | null;
  /**
   * Role
   * @example "user"
   */
  role?: string | null;
  /**
   * Updated at timestamp
   * @example "2023-01-01T00:00:00Z"
   */
  updated_at?: string | null;
  /** Identities */
  identities?: IUserIdentityDto[] | null;
  /**
   * Is anonymous
   * @example false
   */
  is_anonymous?: boolean | null;
  /** Factors */
  factors?: IFactorDto[] | null;
}

export interface ISessionResponseDto {
  /**
   * The oauth provider token. If present, this can be used to make external API requests to the oauth provider used.
   * @example "provider_token_example"
   */
  provider_token?: string | null;
  /**
   * The oauth provider refresh token. If present, this can be used to refresh the provider_token via the oauth provider's API.
   * @example "provider_refresh_token_example"
   */
  provider_refresh_token?: string | null;
  /**
   * The access token jwt. It is recommended to set the JWT_EXPIRY to a shorter expiry value.
   * @example "access_token_example"
   */
  access_token: string;
  /**
   * A one-time used refresh token that never expires.
   * @example "refresh_token_example"
   */
  refresh_token: string;
  /**
   * The number of seconds until the token expires (since it was issued). Returned when a login is confirmed.
   * @example 3600
   */
  expires_in: number;
  /**
   * A timestamp of when the token will expire. Returned when a login is confirmed.
   * @example 1622548800
   */
  expires_at?: number | null;
  /**
   * Token type, typically "Bearer".
   * @example "Bearer"
   */
  token_type: string;
  /** User object */
  user: ISupabaseUserDto;
}

export interface IAuthInfoResponse {
  user: IUser;
}

export interface IAuthExternalDto {
  /** @example "test@ratlab.com" */
  email: string;
  /** @example "q1w2e3" */
  password: string;
}

export interface IAuthRegisterDto {
  /**
   * The user name
   * @example "John"
   */
  name: string;
  /**
   * The user email
   * @example "john.doe@example.com"
   */
  email: string;
  /**
   * The user password
   * @example "password123"
   */
  password: string;
  /**
   * Acceptance of user policy
   * @example true
   */
  policy: boolean;
  /**
   * Firebase USER uid
   * @example "1234567890"
   */
  firebase_uid: string;
}

export interface IPlatform {
  /** The unique identifier of a platform. */
  plt_id: string;
  /** Name of the platform, e.g., "ANDROID", "IOS". */
  plt_name: string;
  /** URL or path to the platform icon. */
  plt_icon: string;
  /** Color of the platform in HEX format, e.g. "#FFFFFF". */
  plt_color: string;
  /** Platform constant value. */
  plt_constant: IPlatformPltConstantEnum;
  /**
   * The date the platform was created.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  plt_created_at: string;
  /**
   * The date the platform was last updated.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  plt_updated_at: string;
  /**
   * The date the platform was deleted (soft).
   * @example "2022-01-01T00:00:00Z"
   */
  plt_deleted_at: object;
}

export interface IOption {
  id: string;
  value: string;
}

export interface IAutocompleteDefaultResponse {
  options: IOption[];
}

export interface IWorkspaceUpsertDto {
  /**
   * The workspace UUID. If empty or not present will create a new workspace, if present with a valid UUID, will update workspace values
   * @example "32fe5547-09c2-43be-bb0d-2a7ff5675c78"
   */
  wrk_id: string | undefined;
  /**
   * The name of the workspace
   * @example "My Awesome Workspace"
   */
  wrk_name: string;
  /**
   * A detailed description of the workspace
   * @example "This workspace is about building a comprehensive platform for users."
   */
  wrk_description: string | null;
  /**
   * The slug of the workspace
   * @uniqueItems true
   * @example "my-awesome-workspace"
   */
  wrk_slug: string;
  /**
   * The organization UUID
   * @example "113a982f-cee7-4748-8dcb-9cfa44e25001"
   */
  org_id: string;
}

export interface IWorkspaceDeleteDto {
  /**
   * A valid UUID for a workspace ID
   * @example "a21114a9-a457-4085-a48a-5afddfac8e9d"
   */
  wrk_id: string;
}

export interface IUsage {
  /**
   * The amount used
   * @example 10
   */
  used: number;
  /**
   * The limit
   * @example 100
   */
  limit: number;
  /**
   * The usage percentage
   * @example "10%"
   */
  amount_percentage_string: string;
  /**
   * The usage percentage
   * @example 10
   */
  amount_percentage_value: number;
  /**
   * The color of the usage percentage
   * @example "#FF0000"
   */
  amount_percentage_color: string;
  /**
   * The remaining amount
   * @example 90
   */
  remaining: number;
  /**
   * The severity of the usage percentage
   * @example "warning"
   */
  severity: IUsageSeverityEnum;
  /**
   * Show warning
   * @example true
   */
  show_warning: boolean;
  /**
   * Is over limit
   * @example true
   */
  is_oapv_limit: boolean;
}

export interface IWorkspaceUsageResponse {
  /**
   * A valid UUID for a workspace ID
   * @example "a21114a9-a457-4085-a48a-5afddfac8e9d"
   */
  wrk_id: string;
  /** Actual usage */
  validations: IUsage;
  /** Approvals usage */
  approvals: IUsage;
}

export interface IWorkspacePhotoUploadDto {
  /**
   * A valid UUID for a workspace ID
   * @example "a21114a9-a457-4085-a48a-5afddfac8e9d"
   */
  wrk_id: string;
  /** @example "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABhklEQVRIS+2VvUoDQRCF" */
  base64: string | null;
}

export interface IApprovalUpsertDto {
  /** ID of the approval */
  apv_id?: string;
  /** Name of the approval */
  apv_name: string;
  /** Flow ID associated with this approval */
  flow_id: string;
  /** Workspace ID associated with this approval */
  wrk_id: string;
}

export interface IApprovalDeleteDto {
  /**
   * A valid UUID for a approval ID
   * @example "a21114a9-a457-4085-a48a-5afddfac8e9d"
   */
  apv_id: string;
}

export interface IApprovalChangeStatusDto {
  /** ID of the approval */
  apv_id?: string;
  /** New status ID */
  sts_id: string;
}

export interface IBillingStatusRequest {
  /**
   * A valid UUID for a organization ID
   * @example "a21114a9-a457-4085-a48a-5afddfac8e9d"
   */
  org_id: string;
}

export interface IBillingStatusPlan {
  /** The unique slug of the billing plan */
  slug: IBillingStatusPlanSlugEnum;
  /** The label of the billing plan */
  label: string;
}

export interface IBillingStatusDto {
  /** The subscription status title of the user */
  title: string;
  /**
   * The informative message about the billing status
   * @example "Your subscription is active."
   */
  message: string;
  /**
   * The color corresponding to the billing status (for frontend display purposes)
   * @example "success"
   */
  color: string;
  /**
   * Flag indicating if a warning should be displayed on the frontend
   * @default false
   * @example true
   */
  should_show_warning: boolean;
  /** The detailed billing object associated with the user */
  billing: IBilling;
  /** The billing plan associated with the user */
  plan: IBillingStatusPlan;
}

export interface IBillingAvailablePlans {
  /**
   * A valid UUID for a organization ID
   * @example "a21114a9-a457-4085-a48a-5afddfac8e9d"
   */
  org_id: string;
}

export interface IStripeFeatureDto {
  /**
   * The name of the feature
   * @example "feature #1"
   */
  name: string;
}

export interface IStripeProductDto {
  /**
   * Unique identifier for the product
   * @example "prod_Oru1rZAKLBRHxQ"
   */
  id: string;
  /**
   * Type of the object
   * @example "product"
   */
  object: string;
  /**
   * Whether the product is currently available for purchase
   * @example true
   */
  active: boolean;
  /**
   * Array of attributes for the product
   * @example []
   */
  attributes: string[];
  /**
   * Time at which the object was created (Unix timestamp)
   * @example 1698014024
   */
  created: number;
  /**
   * ID of the default price for this product
   * @example "price_1O4ADMBX1OXnOEq92IkcYhlj"
   */
  default_price: string;
  /**
   * Product description
   * @example null
   */
  description: string;
  /**
   * Array of features for the product
   * @example [{"name":"Feat #1"},{"name":"Feat #2"},{"name":"Feat #3"}]
   */
  features: IStripeFeatureDto[];
  /**
   * Array of URLs for images of the product
   * @example []
   */
  images: string[];
  /**
   * Whether the object exists in live mode
   * @example false
   */
  livemode: boolean;
  /**
   * Metadata associated with the product
   * @example {}
   */
  metadata: object;
  /**
   * Product's name
   * @example "free"
   */
  name: string;
  /**
   * Dimensions of the product for shipping purposes
   * @example null
   */
  package_dimensions: object;
  /**
   * Whether this product is shipped (physical goods)
   * @example null
   */
  shippable: boolean;
  /**
   * Statement descriptor for the product
   * @example null
   */
  statement_descriptor: string;
  /**
   * Tax code ID
   * @example null
   */
  tax_code: string;
  /**
   * Type of the product (good or service)
   * @example "service"
   */
  type: string;
  /**
   * Label representing units of the product
   * @example null
   */
  unit_label: string;
  /**
   * Time at which the object was last updated (Unix timestamp)
   * @example 1698014024
   */
  updated: number;
  /**
   * URL of a publicly-accessible webpage for the product
   * @example null
   */
  url: string;
}

export interface IStripeRecurringDto {
  /**
   * Specifies how to compute the usage aggregation for the price.
   * @example null
   */
  aggregate_usage: string;
  /**
   * The frequency with which a subscription is billed.
   * @example "year"
   */
  interval: string;
  /**
   * The number of intervals between each subscription billing.
   * @example 1
   */
  interval_count: number;
  /**
   * The number of days in a trial period or `null` if the price is not on trial.
   * @example null
   */
  trial_period_days: number;
}

export interface IStripePriceDto {
  /**
   * Unique identifier for the object.
   * @example "price_1HzlVZGK5jyOzuO3d81YVvT1"
   */
  id: string;
  /**
   * String representing the object's type. Objects of the same type share the same value.
   * @example "price"
   */
  object: string;
  /**
   * Whether the price can be used for new purchases.
   * @example true
   */
  active: boolean;
  /**
   * Describes how to compute the price per period.
   * @example "per_unit"
   */
  billing_scheme: string;
  /**
   * Time at which the object was created. Measured in seconds since the Unix epoch.
   * @example 1622524800
   */
  created: number;
  /**
   * Three-letter ISO currency code, in lowercase. Must be a supported currency.
   * @example "usd"
   */
  currency: string;
  /**
   * Prices defined in each available currency option.
   * @example {"usd":{"unit_amount":1000}}
   */
  currency_options: object;
  /** Configuration for the amount to be adjusted by the customer during Checkout Sessions and Payment Links. */
  custom_unit_amount: object;
  /**
   * Has the value true if the object exists in live mode or the value false if the object exists in test mode.
   * @example true
   */
  livemode: boolean;
  /**
   * A lookup key used to retrieve prices dynamically from a static string.
   * @example "spring_sale"
   */
  lookup_key: string;
  /**
   * Set of key-value pairs that you can attach to an object.
   * @example {"size":"medium","color":"blue"}
   */
  metadata: object;
  /**
   * A brief description of the price, hidden from customers.
   * @example "Monthly subscription"
   */
  nickname: string;
  /**
   * The ID of the product this price is associated with.
   * @example "prod_Oru1rZAKLBRHxQ"
   */
  product: string;
  /** The recurring components of a price such as interval and usage_type. */
  recurring: IStripeRecurringDto;
  /**
   * Specifies whether the price is considered inclusive of taxes or exclusive of taxes.
   * @example "exclusive"
   */
  tax_behavior: string;
  /** Each element represents a pricing tier. */
  tiers: string[];
  /**
   * Defines if the tiering price should be graduated or volume based.
   * @example "volume"
   */
  tiers_mode: string;
  /** Apply a transformation to the reported usage or set quantity before computing the amount billed. */
  transform_quantity: object;
  /**
   * One of one_time or recurring depending on whether the price is for a one-time purchase or a recurring (subscription) purchase.
   * @example "recurring"
   */
  type: string;
  /**
   * The unit amount in cents to be charged, represented as a whole integer if possible.
   * @example 1000
   */
  unit_amount: number;
  /**
   * The unit amount in cents to be charged, represented as a decimal string with at most 12 decimal places.
   * @example "1000.00"
   */
  unit_amount_decimal: string;
}

export interface IStripeMetadataDto {
  /** Stripe product metadata */
  stripe_product_metadata: IStripeProductDto;
  /** Stripe price metadata */
  stripe_price_metadata: IStripePriceDto;
}

export interface IStripeProductListDto {
  /** The unique identifier of a stripe_product. */
  stp_id: string;
  /** The ID of the product item in Stripe. */
  stp_product_id: string;
  /** The ID of the pricing/plan item in Stripe. */
  stp_product_price_id: string;
  /** The chosen plan for the product. */
  stp_plan_type: IStripeProductListDtoStpPlanTypeEnum;
  /** The interval of the product. */
  stp_interval: string;
  /** Internal notes for the product. */
  stp_internal_notes: string;
  /** The product is active. */
  stp_is_active: boolean;
  /**
   * The date the product was created.
   * @format date-time
   */
  stp_created_at: string;
  /**
   * The date the product was last updated.
   * @format date-time
   */
  stp_updated_at: string;
  /** The date the product was deleted. */
  stp_deleted_at: object;
  /** List of billings associated with the stripe product. */
  billings: string[];
  /** Stripe product metadata */
  stripe_metadata: IStripeMetadataDto;
}

export interface IAvailablePlansDto {
  /**
   * Flag indicating if the plan is the current plan for the user
   * @default false
   * @example false
   */
  is_current_plan: boolean;
  /** The detailed product object */
  product: IStripeProductListDto;
  /**
   * The text to display on the button
   * @example "Upgrade | Downgrade | Manage subscription"
   */
  button_text: string;
}

export interface IBillingCustomerPortal {
  /**
   * A valid UUID for a organization ID
   * @example "a21114a9-a457-4085-a48a-5afddfac8e9d"
   */
  org_id: string;
}

export interface IUpdateUserDto {
  /** Name of the user. */
  usu_name?: string;
  /** Email of the user. */
  usu_email?: string;
  /** Profile photo of the user. */
  usu_profile_photo?: string;
}

export interface IConfig {
  /**
   * The configuration ID
   * @example "927869d9-d06d-4860-9278-f6b1e5263102"
   */
  conf_id: string;
  /**
   * Type of config, e.g., "EMAIL", "SMS".
   * @example "EMAIL"
   */
  conf_type: IConfigConfTypeEnum;
  /**
   * Title of the config.
   * @example "Email Configuration"
   */
  conf_title: string;
  /**
   * Description of the config.
   * @example "Configuration for sending emails."
   */
  conf_description: string;
  /**
   * Default value of the config.
   * @example "noreply@example.com"
   */
  conf_default_value: string;
  /**
   * Form type of the config.
   * @example "TEXT"
   */
  conf_form_type: IConfigConfFormTypeEnum;
  /**
   * Config category
   * @example "Email"
   */
  conf_category: string;
  /**
   * The date the config was created.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  conf_created_at: string;
  /**
   * The date the config was last updated.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  conf_updated_at: string;
  /**
   * The date the config was deleted (soft).
   * @example "2022-01-01T00:00:00Z"
   */
  conf_deleted_at: object;
}

export interface IUserConfigResponseDto {
  /**
   * The value associated with the configuration
   * @example "true"
   */
  value: string;
  /**
   * The configuration object
   * @example {"conf_id":"eac7c3ce-b63c-423e-b212-9967c075f67d","conf_type":"EMAIL","conf_title":"Atualizações de faturamento","conf_description":"Atualizações sobre seu faturamento e pagamentos.","conf_default_value":"true","conf_form_type":"CHECKBOX","conf_category":"Notificações por email"}
   */
  config: IConfig;
}

export interface IUserConfigGroupedByCategory {
  /**
   * The category of the configuration
   * @example "Email notifications"
   */
  category: string;
  /** The configurations associated with the category */
  configs: IUserConfigResponseDto[];
}

export interface IUserConfigUpdateDto {
  /**
   * The configuration ID
   * @example "927869d9-d06d-4860-9278-f6b1e5263102"
   */
  conf_id: string;
  /**
   * The new value for the configuration
   * @example "true"
   */
  new_value: string;
}

export interface IOrganizationFavorteRequest {
  /**
   * The organization UUID. If empty or not present will create a new organization, if present with a valid UUID, will update organization values
   * @example "32fe5547-09c2-43be-bb0d-2a7ff5675c78"
   */
  org_id: string | undefined;
}

export interface IUserPhotoUploadDto {
  /**
   * A valid UUID for a user ID
   * @example "a21114a9-a457-4085-a48a-5afddfac8e9d"
   */
  usu_id: string;
  /** @example "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABhklEQVRIS+2VvUoDQRCF" */
  base64: string | null;
}

export interface IOnboardingQuestionsDto {
  /**
   * The unique identifier of the question
   * @example "58fd6bff-605b-44fb-abd5-1eeaa1dca11f"
   */
  id: string;
  /**
   * The order of the question
   * @example 1
   */
  order: number;
  /**
   * The text of the question
   * @example "What is the name of your organization?"
   */
  question_title: string;
  /**
   * The text of the question
   * @example "What is the name of your organization?"
   */
  question_description: string | null;
  /**
   * The field name of the question
   * @example "organization_name"
   */
  field_key: string;
  /**
   * The type of the question
   * @example "text"
   */
  type: IOnboardingQuestionsDtoTypeEnum;
  /**
   * Whether the question is required
   * @example true
   */
  required: boolean;
  /**
   * The options for the question
   * @example ["Daily","Weekly","Monthly","Quarterly","Annually"]
   */
  options?: string[];
}

export interface IOrganizationOnboardingFillQuestionsDto {
  /**
   * A valid UUID for a organization ID
   * @example "a21114a9-a457-4085-a48a-5afddfac8e9d"
   */
  org_id: string;
  /**
   * The questions to be answered
   * @example {"organization_name":"My Organization","organization_type":"Non-profit","organization_size":100,"organization_description":"We are a non-profit organization that helps animals","organization_website":"https://myorganization.com"}
   */
  questions: object;
}

export interface IOrganizationUpsertDto {
  /**
   * The organization UUID. If empty or not present will create a new organization, if present with a valid UUID, will update organization values
   * @example "32fe5547-09c2-43be-bb0d-2a7ff5675c78"
   */
  org_id: string | undefined;
  /**
   * The name of the organization
   * @example "My Awesome Organization"
   */
  org_name: string;
}

export interface IOrganizationDeleteDto {
  /**
   * A valid UUID for a organization ID
   * @example "a21114a9-a457-4085-a48a-5afddfac8e9d"
   */
  org_id: string;
}

export interface IOrganizationAcceptInvitationDto {
  /**
   * The unique code for the invitation.
   * @example "67100f8b54203c543c3aa28f92ced8b15634826069142b0e6c23c263a91eac49"
   */
  orgi_invitation_code: string;
}

export interface IOrganizationInviteCancelDto {
  /**
   * A valid UUID for a organization ID
   * @example "a21114a9-a457-4085-a48a-5afddfac8e9d"
   */
  org_id: string;
  /**
   * A valid UUID for a organization-invitation ID
   * @example "03b77a2f-48a5-4a5b-bd7f-0bc38bac87fa"
   */
  orgi_id: string;
}

export type IUpdateResult = object;

export interface IOrganizationInviteResendDto {
  /**
   * A valid UUID for a organization ID
   * @example "a21114a9-a457-4085-a48a-5afddfac8e9d"
   */
  org_id: string;
  /**
   * The unique code for the invitation.
   * @example "67100f8b54203c543c3aa28f92ced8b15634826069142b0e6c23c263a91eac49"
   */
  orgi_invitation_code: string;
}

export interface IOrganizationInviteDto {
  /**
   * A valid UUID for a organization ID
   * @example "a21114a9-a457-4085-a48a-5afddfac8e9d"
   */
  org_id: string;
  /**
   * A valid email address
   * @example "johndoe@example.com"
   */
  email: string;
  /**
   * The type of the role.
   * @example "MEMBER"
   */
  role_constant: IOrganizationInviteDtoRoleConstantEnum;
}

export interface IOrganizationInvitationLinkDto {
  /**
   * The unique ID of the invitation.
   * @format uuid
   * @example "a1b2c3d4-e5f6-7g8h-9i0j-k1l2m3n4o5p6"
   */
  orgi_id: string;
  /**
   * The email address to which the invitation is sent.
   * @example "user@example.com"
   */
  orgi_email: string;
  /**
   * The unique code for the invitation.
   * @example "67100f8b54203c543c3aa28f92ced8b15634826069142b0e6c23c263a91eac49"
   */
  orgi_invitation_code: string;
  /**
   * Whether the invitation has been accepted.
   * @example false
   */
  orgi_accepted: boolean;
  /**
   * The date the organization invitation was created.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  orgi_created_at: string;
  /**
   * The date the organization invitation was last updated.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  orgi_updated_at: string;
  /**
   * The date the organization invitation was deleted (soft).
   * @example "2022-01-01T00:00:00Z"
   */
  orgi_deleted_at: object;
  organization: IOrganization;
  /**
   * The invitation link to join the organization.
   * @example "http://localhost:3000/organization/invite"
   */
  invitation_link: string;
}

export interface IOrganizationUninviteDto {
  /**
   * A valid UUID for a organization ID
   * @example "a21114a9-a457-4085-a48a-5afddfac8e9d"
   */
  org_id: string;
  /**
   * A valid UUID for a organization ID
   * @example "a21114a9-a457-4085-a48a-5afddfac8e9d"
   */
  usu_id: string;
}

export interface ICollaboratorPermissionDto {
  /**
   * A valid UUID for a organization ID
   * @example "a21114a9-a457-4085-a48a-5afddfac8e9d"
   */
  org_id: string;
  /**
   * A valid UUID for a organization ID
   * @example "a21114a9-a457-4085-a48a-5afddfac8e9d"
   */
  usu_id: string;
}

export interface ICollaboratorPermissionResponseDto {
  /**
   * A valid UUID for a organization ID
   * @example "a21114a9-a457-4085-a48a-5afddfac8e9d"
   */
  org_id: string;
  /**
   * A valid UUID for a organization ID
   * @example "a21114a9-a457-4085-a48a-5afddfac8e9d"
   */
  usu_id: string;
  /**
   * Whether the user is an admin in the organization
   * @example true
   */
  is_admin: boolean;
  /**
   * Whether the user is a developer in the organization
   * @example true
   */
  is_developer: boolean;
  /**
   * Whether the user is a viewer in the organization
   * @example true
   */
  is_viewer: boolean;
  /**
   * Whether the user is a finance in the organization
   * @example true
   */
  is_finance: boolean;
}

export interface IPlanFeatureDetailResponse {
  /**
   * The type of product
   * @example "essential"
   */
  type: IPlanFeatureDetailResponseTypeEnum;
  /**
   * The key for the feature
   * @example "email_support"
   */
  key: IPlanFeatureDetailResponseKeyEnum;
  /**
   * The title of the feature
   * @example "Email support"
   */
  title: string;
  /**
   * The description of the feature
   * @example "Get support via email (included)"
   */
  description: string;
  /**
   * Whether the feature is available
   * @example true
   */
  has_feature: boolean;
}

export interface IPlanFeaturesResponseDto {
  email_support: IPlanFeatureDetailResponse;
  discord_support: IPlanFeatureDetailResponse;
  i18n: IPlanFeatureDetailResponse;
}

export interface IOrganizationUsageResponse {
  /**
   * A valid UUID for a workspace ID
   * @example "a21114a9-a457-4085-a48a-5afddfac8e9d"
   */
  org_id: string;
  /** Collaborators usage */
  collaborators: IUsage;
  /** Workspaces usage */
  workspaces: IUsage;
}

export interface IOrganizationPhotoUploadDto {
  /**
   * A valid UUID for a organization ID
   * @example "a21114a9-a457-4085-a48a-5afddfac8e9d"
   */
  org_id: string;
  /** @example "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABhklEQVRIS+2VvUoDQRCF" */
  base64: string | null;
}

export interface IUpsertPersonalizationDto {
  /** The unique ID of the personalization */
  per_id?: string;
  /** The primary hex color of the personalization */
  per_primary_hex_color?: string;
  /** The text hex color of the personalization */
  per_text_hex_color?: string;
  /** URL of reference */
  per_url?: string;
}

export interface IUpsertStatusDto {
  /**
   * The ID of the status
   * @example "uuid"
   */
  sts_id: string;
  /**
   * The name of the status
   * @example "In Progress"
   */
  sts_name: string;
  /**
   * The color associated with the status
   * @example "#00ff00"
   */
  sts_color: string;
  /**
   * The order of the status
   * @example 1
   */
  sts_order: number;
  /**
   * The ID of the workspace status group
   * @example "uuid"
   */
  wsg_id: string;
  /**
   * The ID of the status type
   * @example "uuid"
   */
  stp_id: string;
}

export interface IApprovalStageUpsertDto {
  /**
   * ID of the approval stage
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  stg_id?: string;
  /**
   * Order of the approval stage
   * @example 1
   */
  stg_order: number;
  /**
   * Name of the stage
   * @example "Initial Review"
   */
  stg_name: string;
  /** Comments for the stage */
  stg_comments?: string;
  /** ID of the approval */
  apv_id: string;
}

export interface IApprovalStageDeleteDto {
  /** ID of the approval stage */
  stg_id: string;
}

export interface IApprovalStageLinkCustomerDto {
  /**
   * Unique identifier of the approval flow stage.
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  stg_id: string;
  /** Customer ID associated with this approval flow stage */
  cust_id: string;
}

export interface IApprovalStageUnlinkCustomerDto {
  /**
   * Unique identifier of the approval flow stage.
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  stg_id: string;
  /** Customer ID associated with this approval flow stage */
  cust_id: string;
}

export interface IApprovalFlowUpsertDto {
  /** ID of the approval flow */
  flow_id?: string;
  /** Name of the approval flow */
  flow_name: string;
  /** Detailed description of the approval flow */
  flow_description?: string;
  /** Workspace ID associated with this approval flow */
  wrk_id: string;
}

export interface IApprovalFlowDeleteDto {
  /** ID of the approval flow */
  flow_id: string;
}

export interface IApprovalFlowStageUpsertDto {
  /**
   * Unique identifier of the approval flow stage.
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  afs_id: string;
  /**
   * Order of the approval stage.
   * @example 1
   */
  afs_order: number;
  /**
   * Name of the stage.
   * @maxLength 255
   * @example "Initial Review"
   */
  afs_name: string;
  /** Detailed description of the stage. */
  afs_description: string | null;
}

export interface IApprovalFlowStageDeleteDto {
  /**
   * Unique identifier of the approval flow stage.
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  afs_id: string;
}

export interface IApprovalFlowStageLinkCustomerDto {
  /**
   * Unique identifier of the approval flow stage.
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  afs_id: string;
  /** Customer ID associated with this approval flow stage */
  cust_id: string;
}

export interface IApprovalFlowStageUnlinkCustomerDto {
  /**
   * Unique identifier of the approval flow stage.
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  afs_id: string;
  /** Customer ID associated with this approval flow stage */
  cust_id: string;
}

export interface ICustomerUpsertDto {
  /**
   * Unique identifier of the customer.
   * @example "b26ad7bc-0e7c-47ae-8086-4995c5999a1f"
   */
  cust_id: string | null;
  /**
   * Name of the customer.
   * @maxLength 255
   * @example "John Doe"
   */
  cust_name: string;
  /**
   * Email of the customer.
   * @maxLength 255
   * @example "john.doe@example.com"
   */
  cust_email: string;
  /**
   * Mobile phone number of the customer.
   * @maxLength 20
   * @example "+1234567890"
   */
  cust_mobile_phone: string | null;
  /** The workspace this customer belongs to. */
  wrk_id: string;
}

export interface ICustomerDeleteDto {
  /**
   * Unique identifier of the customer to be deleted.
   * @example "b26ad7bc-0e7c-47ae-8086-4995c5999a1f"
   */
  cust_id: string;
}

export interface ICustomerPhotoUploadDto {
  /**
   * A valid UUID for a customer ID
   * @example "a21114a9-a457-4085-a48a-5afddfac8e9d"
   */
  cust_id: string;
  /** @example "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABhklEQVRIS+2VvUoDQRCF" */
  base64: string | null;
}

export interface IApprovalItemAssetUpsertDto {
  /**
   * The UUID of the approval item asset order
   * @format uuid
   */
  aia_id?: string | null;
  /**
   * The order of the asset
   * @example 1
   */
  aia_order: number;
  /** @format binary */
  file?: File;
  /** The file name of the asset */
  should_delete?: string | null;
}

export interface IApprovalItemUpsertDto {
  /** The approval this item belongs to. */
  apv_id: string;
  /** The approval this item belongs to. */
  item_id?: string;
  /**
   * Description of the item.
   * @example "Item Description"
   */
  item_description?: string;
  /**
   * The order of the item.
   * @example 1
   */
  item_order?: number;
  /** Assets associated with this item. */
  assets?: IApprovalItemAssetUpsertDto[];
}

export interface IApprovalItemAssetDeleteDto {
  /**
   * The UUID of the approval item asset order
   * @format uuid
   */
  aia_id: string | null;
}

export interface IApprovalItemDeleteDto {
  /**
   * The UUID of the approval item
   * @format uuid
   */
  item_id: string;
}

export interface IMetricDto {
  /**
   * Título da métrica
   * @example "Total de aprovações"
   */
  title: string;
  /**
   * Tooltip da métrica
   * @example "Quantidade total de aprovações"
   */
  tooltip: string;
  /**
   * Valor da métrica
   * @example 255
   */
  value: number;
}

export interface IApprovalStatusMetricDto {
  /**
   * Título da métrica
   * @example "Total de aprovações"
   */
  title: string;
  /**
   * Tooltip da métrica
   * @example "Quantidade total de aprovações"
   */
  tooltip: string;
  /**
   * Valor da métrica
   * @example 255
   */
  value: number;
  /**
   * Status associado à métrica
   * @example "NOT_STARTED"
   */
  status: IApprovalStatusMetricDtoStatusEnum;
}

export interface IApprovalsDto {
  /**
   * Título da seção
   * @example "Total de aprovações"
   */
  title: string;
  /**
   * Tooltip da seção
   * @example "Quantidade total de aprovações"
   */
  description: string;
  total: IMetricDto;
  notStarted: IApprovalStatusMetricDto;
  pending: IApprovalStatusMetricDto;
  requestingChanges: IApprovalStatusMetricDto;
  approved: IApprovalStatusMetricDto;
}

export interface IRevisionStatusMetricDto {
  /**
   * Título da métrica
   * @example "Total de aprovações"
   */
  title: string;
  /**
   * Tooltip da métrica
   * @example "Quantidade total de aprovações"
   */
  tooltip: string;
  /**
   * Valor da métrica
   * @example 255
   */
  value: number;
  /**
   * Status associado à métrica
   * @example "approved"
   */
  status: IRevisionStatusMetricDtoStatusEnum;
}

export interface IRevisionsDto {
  /**
   * Título da seção
   * @example "Total de aprovações"
   */
  title: string;
  /**
   * Tooltip da seção
   * @example "Quantidade total de aprovações"
   */
  description: string;
  total: IMetricDto;
  pending: IRevisionStatusMetricDto;
  needingReview: IRevisionStatusMetricDto;
  completed: IRevisionStatusMetricDto;
}

export interface IUsersDto {
  /**
   * Título da seção
   * @example "Total de aprovações"
   */
  title: string;
  /**
   * Tooltip da seção
   * @example "Quantidade total de aprovações"
   */
  description: string;
  total: IMetricDto;
}

export interface ICustomersDto {
  /**
   * Título da seção
   * @example "Total de aprovações"
   */
  title: string;
  /**
   * Tooltip da seção
   * @example "Quantidade total de aprovações"
   */
  description: string;
  total: IMetricDto;
}

export interface ILastApprovalsDto {
  /**
   * Título da seção
   * @example "Total de aprovações"
   */
  title: string;
  /**
   * Tooltip da seção
   * @example "Quantidade total de aprovações"
   */
  description: string;
  approvals: IApproval[];
}

export interface IWorkspacesDto {
  /**
   * Título da seção
   * @example "Total de aprovações"
   */
  title: string;
  /**
   * Tooltip da seção
   * @example "Quantidade total de aprovações"
   */
  description: string;
  total: IMetricDto;
}

export interface IDashboardResponseDto {
  approvals: IApprovalsDto;
  revisions: IRevisionsDto;
  users: IUsersDto;
  customers: ICustomersDto;
  lastApprovals: ILastApprovalsDto;
  workspaces: IWorkspacesDto;
}

export interface ICommentAddRequestDto {
  /**
   * The comment for the item.
   * @example "Needs changes"
   */
  aic_comment: string;
  /**
   * Indicates if the comment was made by a user or a customer.
   * @example "customer"
   */
  aic_by: ICommentAddRequestDtoAicByEnum;
  /** The user who made the comment, if applicable. */
  usu_id: string | null;
  /** The customer who made the comment, if applicable. */
  cust_id: string | null;
}

export interface IApprovalItemRevisionCommentRequestDto {
  /** ID of the approval flow */
  air_id: string;
  /** Comment to add to the approval flow */
  comment: ICommentAddRequestDto;
}

export interface IApprovalItemRevisionCommentResponseDto {
  /**
   * Unique identifier of the approval item comment.
   * @example "123e4567-e89b-12d3-a456-426614174000"
   */
  aic_id: string;
  /**
   * The comment for the item.
   * @example "Needs changes"
   */
  aic_comment: string;
  /**
   * The color of the message.
   * @example "primary"
   */
  aic_color?: IApprovalItemRevisionCommentResponseDtoAicColorEnum;
  /**
   * Indicates if the comment was made by a user or a customer.
   * @example "customer"
   */
  aic_by: IApprovalItemRevisionCommentResponseDtoAicByEnum;
  /** The user who made the comment, if applicable. */
  user: IUser | null;
  /** The customer who made the comment, if applicable. */
  customer: ICustomer | null;
  /** The revision this comment belongs to. */
  revision: IApprovalItemRevision;
  /**
   * The date the comment was created.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  aic_created_at: string;
  /**
   * The date the comment was last updated.
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  aic_updated_at: string;
  /**
   * The date the comment was deleted (soft).
   * @format date-time
   * @example "2022-01-01T00:00:00Z"
   */
  aic_deleted_at: string | null;
}

export interface IIPublicPlanAvailableDto {
  /** Metadata for yearly subscription plans in Stripe */
  year: IStripeMetadataDto[];
  /** Metadata for monthly subscription plans in Stripe */
  monthly: IStripeMetadataDto[];
}

export interface IStatusApprovalRequestDto {
  /**
   * The unique identifier of the approval.
   * @format uuid
   * @example "123e4567-es89b-12d3-a456-426614174000"
   */
  asc_id: string;
}

export interface IStatusApprovalResponseDto {
  /** The approval status object. */
  status: IStatus;
}

export interface ICheckApprovalDto {
  /**
   * The slug of the workspace.
   * @maxLength 255
   * @example "my-awesome-workspace"
   */
  wrk_slug: string;
  /**
   * The unique identifier of the approval.
   * @format uuid
   * @example "123e4567-es89b-12d3-a456-426614174000"
   */
  asc_id: string;
}

export interface ICheckApprovalResponseDto {
  /** The approval object. */
  approvalStageCustomer: IApprovalStageCustomer;
}

/**
 * The type of the status
 * @uniqueItems true
 * @example "NOT_STARTED"
 */
export enum IStatusTypeStpTypeEnum {
  NOT_STARTED = "NOT_STARTED",
  IN_REVIEW = "IN_REVIEW",
  REQUEST_CHANGED = "REQUEST_CHANGED",
  DONE = "DONE",
}

/** @example "public" */
export enum IBucketBktNameEnum {
  Public = "public",
  Private = "private",
}

/** The chosen plan for the product. */
export enum IStripeProductStpPlanTypeEnum {
  Basic = "basic",
  Essential = "essential",
  Enterprise = "enterprise",
}

/** Indicates if the billing (subscription) is active or not. */
export enum IBillingBlgStripeSubscriptionStatusEnum {
  Active = "active",
  Canceled = "canceled",
  Incomplete = "incomplete",
  IncompleteExpired = "incomplete_expired",
  PastDue = "past_due",
  Paused = "paused",
  Trialing = "trialing",
  Unpaid = "unpaid",
  Inactive = "inactive",
}

/**
 * The type of the role.
 * @example "OWNER"
 */
export enum IRoleRoleConstantEnum {
  OWNER = "OWNER",
  FINANCE = "FINANCE",
  MEMBER = "MEMBER",
  VIEWER = "VIEWER",
}

/**
 * The color of the message.
 * @example "primary"
 */
export enum IApprovalItemCommentAicColorEnum {
  Primary = "primary",
  Secondary = "secondary",
  Error = "error",
  Info = "info",
  Success = "success",
  Warning = "warning",
}

/**
 * Indicates if the comment was made by a user or a customer.
 * @example "customer"
 */
export enum IApprovalItemCommentAicByEnum {
  User = "user",
  Customer = "customer",
  System = "system",
}

/**
 * The status of the approval (approved/rejected).
 * @example "approved"
 */
export enum IApprovalItemRevisionAirStatusEnum {
  Pending = "pending",
  Approved = "approved",
  Requested = "requested",
}

/** Platform constant value. */
export enum IPlatformPltConstantEnum {
  INSTAGRAM = "INSTAGRAM",
  FACEBOOK = "FACEBOOK",
  TWITTER = "TWITTER",
  LINKEDIN = "LINKEDIN",
  YOUTUBE = "YOUTUBE",
  TIKTOK = "TIKTOK",
}

/**
 * The severity of the usage percentage
 * @example "warning"
 */
export enum IUsageSeverityEnum {
  Success = "success",
  Info = "info",
  Warning = "warning",
  Error = "error",
}

/** The unique slug of the billing plan */
export enum IBillingStatusPlanSlugEnum {
  Basic = "basic",
  Essential = "essential",
  Enterprise = "enterprise",
}

/** The chosen plan for the product. */
export enum IStripeProductListDtoStpPlanTypeEnum {
  Basic = "basic",
  Essential = "essential",
  Enterprise = "enterprise",
}

/**
 * Type of config, e.g., "EMAIL", "SMS".
 * @example "EMAIL"
 */
export enum IConfigConfTypeEnum {
  EMAIL = "EMAIL",
  SMS = "SMS",
}

/**
 * Form type of the config.
 * @example "TEXT"
 */
export enum IConfigConfFormTypeEnum {
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  CHECKBOX = "CHECKBOX",
}

/**
 * The type of the question
 * @example "text"
 */
export enum IOnboardingQuestionsDtoTypeEnum {
  Text = "text",
  Number = "number",
  Select = "select",
  Textarea = "textarea",
  Boolean = "boolean",
}

/**
 * The type of the role.
 * @example "MEMBER"
 */
export enum IOrganizationInviteDtoRoleConstantEnum {
  OWNER = "OWNER",
  FINANCE = "FINANCE",
  MEMBER = "MEMBER",
  VIEWER = "VIEWER",
}

/**
 * The type of product
 * @example "essential"
 */
export enum IPlanFeatureDetailResponseTypeEnum {
  Basic = "basic",
  Essential = "essential",
  Enterprise = "enterprise",
}

/**
 * The key for the feature
 * @example "email_support"
 */
export enum IPlanFeatureDetailResponseKeyEnum {
  EmailSupport = "email_support",
  DiscordSupport = "discord_support",
  I18N = "i18n",
}

/**
 * Status associado à métrica
 * @example "NOT_STARTED"
 */
export enum IApprovalStatusMetricDtoStatusEnum {
  NOT_STARTED = "NOT_STARTED",
  IN_REVIEW = "IN_REVIEW",
  REQUEST_CHANGED = "REQUEST_CHANGED",
  DONE = "DONE",
}

/**
 * Status associado à métrica
 * @example "approved"
 */
export enum IRevisionStatusMetricDtoStatusEnum {
  Pending = "pending",
  Approved = "approved",
  Requested = "requested",
}

/**
 * Indicates if the comment was made by a user or a customer.
 * @example "customer"
 */
export enum ICommentAddRequestDtoAicByEnum {
  User = "user",
  Customer = "customer",
  System = "system",
}

/**
 * The color of the message.
 * @example "primary"
 */
export enum IApprovalItemRevisionCommentResponseDtoAicColorEnum {
  Primary = "primary",
  Secondary = "secondary",
  Error = "error",
  Info = "info",
  Success = "success",
  Warning = "warning",
}

/**
 * Indicates if the comment was made by a user or a customer.
 * @example "customer"
 */
export enum IApprovalItemRevisionCommentResponseDtoAicByEnum {
  User = "user",
  Customer = "customer",
  System = "system",
}

import type { AxiosInstance, AxiosRequestConfig, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type ? { "Content-Type": type } : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .then((response) => response.data);
  };
}

/**
 * @title Thumbz API
 * @contact
 *
 * This API uses the concept of "upset". If the `UUID` is not present in the request, it indicates a creation action. If the `UUID` is present, it indicates an update action.
 *
 * ## TOKEN
 *
 * To generate a valid TOKEN to authorize these requests, you MUST first login to FIREBASE on frontend. Then, use the `/auth/login` endpoint to generate the token. Once you have the token, click the "Authorize" button below and paste the token value into the input field, then click "Authorize" again!.
 *
 * Actually this API does not have API-KEY for the requests, but is planned to have in the future to the paid plans.
 *
 * The only endpoint that requires an API-KEY is the `/check-version` endpoint. Which is the unique endpoint that your app will use.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  audits = {
    /**
     * No description
     *
     * @tags Audits
     * @name AuditControllerListApiAudits
     * @request GET:/audits/api
     * @secure
     * @response `200` `IAuditListApiResponse`
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    auditControllerListApiAudits: (
      query: {
        /**
         * The page is the number to paginate response
         * @min 1
         * @default 1
         */
        page: number;
        /**
         * The ammount of data in the result
         * @min 10
         * @max 100
         * @default 10
         */
        size: number;
        /**
         * @format uuid
         * @example "a1b2c3d4-e5f6-7g8h-9i0j-k1l2m3n4o5p6"
         */
        usu_id: string;
        /** One of the API endpoints URI like `/maintenance/list` */
        api_uri: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<IAuditListApiResponse, IApiUnauthorized>({
        path: `/audits/api`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Audits
     * @name AuditControllerListActivities
     * @request GET:/audits/activities
     * @secure
     * @response `200` `IAuditListActivitiesResponse`
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    auditControllerListActivities: (
      query: {
        /**
         * The page is the number to paginate response
         * @min 1
         * @default 1
         */
        page: number;
        /**
         * The ammount of data in the result
         * @min 10
         * @max 100
         * @default 10
         */
        size: number;
        /**
         * A valid UUID for a workspace ID
         * @example "4bafbd2e-5907-4cdd-87c0-f83e1029ad46"
         */
        wrk_id: string;
        /**
         * A valid UUID for a workspace ID
         * @example "a21114a9-a457-4085-a48a-5afddfac8e9d"
         */
        usu_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<IAuditListActivitiesResponse, IApiUnauthorized>({
        path: `/audits/activities`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Audits
     * @name AuditControllerListTwilio
     * @request GET:/audits/twilio
     * @secure
     * @response `200` `IAuditListTwilioResponse`
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    auditControllerListTwilio: (
      query: {
        /**
         * The page is the number to paginate response
         * @min 1
         * @default 1
         */
        page: number;
        /**
         * The ammount of data in the result
         * @min 10
         * @max 100
         * @default 10
         */
        size: number;
        /**
         * A valid User UUID
         * @format uuid
         */
        usu_id?: string;
        /** A valid phone number like `+5511123456789` */
        twl_to?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<IAuditListTwilioResponse, IApiUnauthorized>({
        path: `/audits/twilio`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Audits
     * @name AuditControllerListLogin
     * @request GET:/audits/login
     * @secure
     * @response `200` `IAuditListLoginResponse`
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    auditControllerListLogin: (
      query: {
        /**
         * The page is the number to paginate response
         * @min 1
         * @default 1
         */
        page: number;
        /**
         * The ammount of data in the result
         * @min 10
         * @max 100
         * @default 10
         */
        size: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<IAuditListLoginResponse, IApiUnauthorized>({
        path: `/audits/login`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  auth = {
    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerLogin
     * @request POST:/auth/login
     * @response `200` `IAuthLoginResponse`
     */
    authControllerLogin: (data: IAuthDto, params: RequestParams = {}) =>
      this.request<IAuthLoginResponse, any>({
        path: `/auth/login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerRefreshToken
     * @request POST:/auth/refresh-token
     * @response `200` `ISessionResponseDto`
     */
    authControllerRefreshToken: (data: IAuthRefreshTokenRequestDto, params: RequestParams = {}) =>
      this.request<ISessionResponseDto, any>({
        path: `/auth/refresh-token`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerValidate
     * @request GET:/auth/info
     * @secure
     * @response `200` `IAuthInfoResponse`
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    authControllerValidate: (params: RequestParams = {}) =>
      this.request<IAuthInfoResponse, IApiUnauthorized>({
        path: `/auth/info`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerFblogin
     * @request POST:/auth/fblogin
     * @response `200` `IAuthLoginResponse`
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    authControllerFblogin: (data: IAuthExternalDto, params: RequestParams = {}) =>
      this.request<IAuthLoginResponse, IApiUnauthorized>({
        path: `/auth/fblogin`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerRegister
     * @request POST:/auth/register
     * @response `200` `IAuthLoginResponse`
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    authControllerRegister: (data: IAuthRegisterDto, params: RequestParams = {}) =>
      this.request<IAuthLoginResponse, IApiUnauthorized>({
        path: `/auth/register`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  platform = {
    /**
     * No description
     *
     * @tags Platform
     * @name PlatformControllerList
     * @request GET:/platform
     * @secure
     * @response `200` `(IPlatform)[]` Returns all platforms
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    platformControllerList: (params: RequestParams = {}) =>
      this.request<IPlatform[], IApiUnauthorized>({
        path: `/platform`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  autocomplete = {
    /**
     * No description
     *
     * @tags Autocomplete
     * @name AutocompleteControllerRoles
     * @request GET:/autocomplete/roles
     * @secure
     * @response `200` `IAutocompleteDefaultResponse` List `ROLES` as options
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    autocompleteControllerRoles: (params: RequestParams = {}) =>
      this.request<IAutocompleteDefaultResponse, IApiUnauthorized>({
        path: `/autocomplete/roles`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Autocomplete
     * @name AutocompleteControllerPlatforms
     * @request GET:/autocomplete/platforms
     * @secure
     * @response `200` `IAutocompleteDefaultResponse` List `PLATFORMS` as options
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    autocompleteControllerPlatforms: (params: RequestParams = {}) =>
      this.request<IAutocompleteDefaultResponse, IApiUnauthorized>({
        path: `/autocomplete/platforms`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Autocomplete
     * @name AutocompleteControllerCountries
     * @request GET:/autocomplete/countries
     * @secure
     * @response `200` `IAutocompleteDefaultResponse` List `PLATFORMS` as options
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    autocompleteControllerCountries: (params: RequestParams = {}) =>
      this.request<IAutocompleteDefaultResponse, IApiUnauthorized>({
        path: `/autocomplete/countries`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  healthCheck = {
    /**
     * No description
     *
     * @tags Health Check
     * @name HealthCheckControllerLiveness
     * @request GET:/health-check/liveness
     * @response `200` `void`
     */
    healthCheckControllerLiveness: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/health-check/liveness`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Health Check
     * @name HealthCheckControllerReadiness
     * @request GET:/health-check/readiness
     * @response `200` `void`
     */
    healthCheckControllerReadiness: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/health-check/readiness`,
        method: "GET",
        ...params,
      }),
  };
  workspace = {
    /**
     * No description
     *
     * @tags Workspace
     * @name WorkspaceControllerList
     * @request GET:/workspace/list
     * @secure
     * @response `200` `(IWorkspace)[]` List all workspaces
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    workspaceControllerList: (
      query: {
        /**
         * The unique ID of the workspace
         * @format uuid
         * @example "a1b2c3d4-e5f6-7g8h-9i0j-k1l2m3n4o5p6"
         */
        wrk_id?: string | null;
        /**
         * The name of the workspace
         * @example "My Awesome Workspace"
         */
        wrk_name?: string | null;
        /**
         * A detailed description of the workspace
         * @example "This workspace is about building a comprehensive platform for users."
         */
        wrk_description?: string | null;
        /**
         * A flag to return relationships of the workspace
         * @default false
         * @example true
         */
        with_relations?: boolean;
        /**
         * A valid UUID for a organization ID
         * @example "a21114a9-a457-4085-a48a-5afddfac8e9d"
         */
        org_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<IWorkspace[], IApiUnauthorized>({
        path: `/workspace/list`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspace
     * @name WorkspaceControllerUpsert
     * @request POST:/workspace/upsert
     * @secure
     * @response `200` `IWorkspace` Upsert a workspace (Insert if not exists, update otherwise)
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    workspaceControllerUpsert: (data: IWorkspaceUpsertDto, params: RequestParams = {}) =>
      this.request<IWorkspace, IApiUnauthorized>({
        path: `/workspace/upsert`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspace
     * @name WorkspaceControllerDelete
     * @request POST:/workspace/delete
     * @secure
     * @response `200` `IWorkspace` Delete a workspace by its id
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    workspaceControllerDelete: (data: IWorkspaceDeleteDto, params: RequestParams = {}) =>
      this.request<IWorkspace, IApiUnauthorized>({
        path: `/workspace/delete`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspace
     * @name WorkspaceControllerGetWorkspaceUsage
     * @request GET:/workspace/usage/{wrk_id}
     * @secure
     * @response `200` `IWorkspaceUsageResponse` Get a organization usage
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    workspaceControllerGetWorkspaceUsage: (wrkId: string, params: RequestParams = {}) =>
      this.request<IWorkspaceUsageResponse, IApiUnauthorized>({
        path: `/workspace/usage/${wrkId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspace
     * @name WorkspaceControllerUpdatePhoto
     * @request PUT:/workspace/photo
     * @secure
     * @response `200` `IWorkspace` Update the organization photo
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    workspaceControllerUpdatePhoto: (data: IWorkspacePhotoUploadDto, params: RequestParams = {}) =>
      this.request<IWorkspace, IApiUnauthorized>({
        path: `/workspace/photo`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspace
     * @name WorkspaceControllerGet
     * @request GET:/workspace/{wrk_id}
     * @secure
     * @response `200` `IWorkspace` Get a workspace by its id
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    workspaceControllerGet: (wrkId: string, params: RequestParams = {}) =>
      this.request<IWorkspace, IApiUnauthorized>({
        path: `/workspace/${wrkId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  approval = {
    /**
     * No description
     *
     * @tags Approval
     * @name ApprovalControllerList
     * @request GET:/approval/list
     * @secure
     * @response `200` `(IApproval)[]` List all approvals
     * @response `401` `void` Unauthorized response
     */
    approvalControllerList: (
      query?: {
        /** Workspace ID to filter approvals */
        wrk_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<IApproval[], void>({
        path: `/approval/list`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Approval
     * @name ApprovalControllerUpsert
     * @request POST:/approval/upsert
     * @secure
     * @response `200` `IApproval` Upsert an approval (Insert if not exists, update otherwise)
     * @response `401` `void` Unauthorized response
     */
    approvalControllerUpsert: (data: IApprovalUpsertDto, params: RequestParams = {}) =>
      this.request<IApproval, void>({
        path: `/approval/upsert`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Approval
     * @name ApprovalControllerDelete
     * @request POST:/approval/delete
     * @secure
     * @response `200` `IApproval` Delete an approval by its id
     * @response `401` `void` Unauthorized response
     */
    approvalControllerDelete: (data: IApprovalDeleteDto, params: RequestParams = {}) =>
      this.request<IApproval, void>({
        path: `/approval/delete`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Approval
     * @name ApprovalControllerGet
     * @request GET:/approval/{apv_id}
     * @secure
     * @response `200` `IApproval` Get an approval by its id
     * @response `401` `void` Unauthorized response
     */
    approvalControllerGet: (apvId: string, params: RequestParams = {}) =>
      this.request<IApproval, void>({
        path: `/approval/${apvId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Approval
     * @name ApprovalControllerChangeStatus
     * @request POST:/approval/change-status
     * @secure
     * @response `200` `IApproval` Change status of an approval
     * @response `401` `void` Unauthorized response
     */
    approvalControllerChangeStatus: (data: IApprovalChangeStatusDto, params: RequestParams = {}) =>
      this.request<IApproval, void>({
        path: `/approval/change-status`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  billing = {
    /**
     * @description Retrieve the billing status for a given user based on their unique ID (usu_id).
     *
     * @tags Billing
     * @name BillingControllerGetBillingStatus
     * @summary Get Billing Status
     * @request POST:/billing/status
     * @secure
     * @response `200` `IBillingStatusDto` Successfully retrieved billing status
     * @response `400` `void` Invalid request parameters
     * @response `401` `IApiUnauthorized` Unauthorized response
     * @response `404` `void` User or billing status not found
     */
    billingControllerGetBillingStatus: (data: IBillingStatusRequest, params: RequestParams = {}) =>
      this.request<IBillingStatusDto, void | IApiUnauthorized>({
        path: `/billing/status`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieve the available billing plans.
     *
     * @tags Billing
     * @name BillingControllerGetAvailablePlans
     * @summary Get Billing Plans
     * @request POST:/billing/plans
     * @secure
     * @response `200` `(IAvailablePlansDto)[]` Successfully retrieved billing plans
     * @response `400` `void` Invalid request parameters
     * @response `401` `IApiUnauthorized` Unauthorized response
     * @response `404` `void` Billing plans not found
     */
    billingControllerGetAvailablePlans: (data: IBillingAvailablePlans, params: RequestParams = {}) =>
      this.request<IAvailablePlansDto[], void | IApiUnauthorized>({
        path: `/billing/plans`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieve the customer portal link.
     *
     * @tags Billing
     * @name BillingControllerGetCustomerPortal
     * @summary Get Customer Portal
     * @request POST:/billing/portal
     * @secure
     * @response `200` `string` Successfully retrieved customer portal link
     * @response `400` `void` Invalid request parameters
     * @response `401` `IApiUnauthorized` Unauthorized response
     * @response `404` `void` Customer portal link not found
     */
    billingControllerGetCustomerPortal: (data: IBillingCustomerPortal, params: RequestParams = {}) =>
      this.request<string, void | IApiUnauthorized>({
        path: `/billing/portal`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  users = {
    /**
     * No description
     *
     * @tags Users
     * @name UserControllerGetUserById
     * @summary Get user by ID
     * @request GET:/users
     * @response `200` `IUser` Get the logged user info
     */
    userControllerGetUserById: (params: RequestParams = {}) =>
      this.request<IUser, any>({
        path: `/users`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerUpdateUser
     * @summary Change photo
     * @request PUT:/users
     * @response `200` `IUser` User updated successfully.
     */
    userControllerUpdateUser: (data: IUpdateUserDto, params: RequestParams = {}) =>
      this.request<IUser, any>({
        path: `/users`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerDeleteUser
     * @summary Delete user
     * @request DELETE:/users
     * @response `200` `void` User deleted successfully.
     */
    userControllerDeleteUser: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerGetUserConfig
     * @summary Get user config
     * @request GET:/users/config
     * @response `200` `(IUserConfigGroupedByCategory)[]` User config fetched successfully.
     */
    userControllerGetUserConfig: (params: RequestParams = {}) =>
      this.request<IUserConfigGroupedByCategory[], any>({
        path: `/users/config`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerUpdateUserConfig
     * @summary Update user config
     * @request PATCH:/users/config
     * @response `200` `(IUserConfigGroupedByCategory)[]` User config updated successfully.
     */
    userControllerUpdateUserConfig: (data: IUserConfigUpdateDto, params: RequestParams = {}) =>
      this.request<IUserConfigGroupedByCategory[], any>({
        path: `/users/config`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerFavorite
     * @request POST:/users/organization/favorite
     * @response `200` `IOrganization` Endpoint to select the favorite organization of the user. Useful to redirect user automatically on login.
     */
    userControllerFavorite: (data: IOrganizationFavorteRequest, params: RequestParams = {}) =>
      this.request<IOrganization, any>({
        path: `/users/organization/favorite`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerGetFavorite
     * @request GET:/users/organization/favorite
     * @response `200` `IOrganization` Endpoint to select the favorite organization of the user. Useful to redirect user automatically on login.
     */
    userControllerGetFavorite: (params: RequestParams = {}) =>
      this.request<IOrganization, any>({
        path: `/users/organization/favorite`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerUpdatePhoto
     * @request PUT:/users/photo
     * @response `200` `IUser` Update the user photo
     */
    userControllerUpdatePhoto: (data: IUserPhotoUploadDto, params: RequestParams = {}) =>
      this.request<IUser, any>({
        path: `/users/photo`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  organization = {
    /**
     * No description
     *
     * @tags Organization
     * @name OrganizationControllerOnboardingQuestions
     * @request GET:/organization/onboarding/questions
     * @secure
     * @response `200` `(IOnboardingQuestionsDto)[]` List all organizations that the user is part of
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    organizationControllerOnboardingQuestions: (params: RequestParams = {}) =>
      this.request<IOnboardingQuestionsDto[], IApiUnauthorized>({
        path: `/organization/onboarding/questions`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name OrganizationControllerFillOnboardingQuestions
     * @request POST:/organization/onboarding/questions
     * @secure
     * @response `200` `IOrganization` Endpoint to fill onboarding questions
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    organizationControllerFillOnboardingQuestions: (
      data: IOrganizationOnboardingFillQuestionsDto,
      params: RequestParams = {},
    ) =>
      this.request<IOrganization, IApiUnauthorized>({
        path: `/organization/onboarding/questions`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name OrganizationControllerList
     * @request GET:/organization/list
     * @secure
     * @response `200` `(IOrganization)[]` List all organizations that the user is part of
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    organizationControllerList: (
      query?: {
        /**
         * The unique ID of the organization
         * @format uuid
         * @example "a1b2c3d4-e5f6-7g8h-9i0j-k1l2m3n4o5p6"
         */
        org_id?: string | null;
        /**
         * The name of the organization
         * @example "My Awesome Organization"
         */
        org_name?: string | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<IOrganization[], IApiUnauthorized>({
        path: `/organization/list`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name OrganizationControllerUpsert
     * @request POST:/organization/upsert
     * @secure
     * @response `200` `IOrganization` Upsert a organization (Insert if not exists, update otherwise)
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    organizationControllerUpsert: (data: IOrganizationUpsertDto, params: RequestParams = {}) =>
      this.request<IOrganization, IApiUnauthorized>({
        path: `/organization/upsert`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name OrganizationControllerDelete
     * @request POST:/organization/delete
     * @secure
     * @response `200` `void` Delete a organization by its id
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    organizationControllerDelete: (data: IOrganizationDeleteDto, params: RequestParams = {}) =>
      this.request<void, IApiUnauthorized>({
        path: `/organization/delete`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name OrganizationControllerAcceptInvite
     * @request POST:/organization/invite/accept
     * @secure
     * @response `200` `IOrganization` Invite a user to a organization
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    organizationControllerAcceptInvite: (data: IOrganizationAcceptInvitationDto, params: RequestParams = {}) =>
      this.request<IOrganization, IApiUnauthorized>({
        path: `/organization/invite/accept`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name OrganizationControllerCancelInvite
     * @request POST:/organization/invite/cancel
     * @secure
     * @response `200` `IUpdateResult` Cancel an invitation to a organization
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    organizationControllerCancelInvite: (data: IOrganizationInviteCancelDto, params: RequestParams = {}) =>
      this.request<IUpdateResult, IApiUnauthorized>({
        path: `/organization/invite/cancel`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name OrganizationControllerInviteResend
     * @request POST:/organization/invite/resend
     * @secure
     * @response `200` `IOrganizationInvitation` Resend an invitation to a organization
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    organizationControllerInviteResend: (data: IOrganizationInviteResendDto, params: RequestParams = {}) =>
      this.request<IOrganizationInvitation, IApiUnauthorized>({
        path: `/organization/invite/resend`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name OrganizationControllerInvite
     * @request POST:/organization/invite
     * @secure
     * @response `200` `IOrganizationInvitation` Invite a user to a organization
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    organizationControllerInvite: (data: IOrganizationInviteDto, params: RequestParams = {}) =>
      this.request<IOrganizationInvitation, IApiUnauthorized>({
        path: `/organization/invite`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name OrganizationControllerGetInvitationByToken
     * @request GET:/organization/invite/token/{orgi_invitation_code}
     * @secure
     * @response `200` `IOrganizationInvitation` Get a invitation by its token
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    organizationControllerGetInvitationByToken: (orgiInvitationCode: string, params: RequestParams = {}) =>
      this.request<IOrganizationInvitation, IApiUnauthorized>({
        path: `/organization/invite/token/${orgiInvitationCode}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name OrganizationControllerGetInvitations
     * @request GET:/organization/invitations/{org_id}
     * @secure
     * @response `200` `(IOrganizationInvitationLinkDto)[]` List all invitations to a organization
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    organizationControllerGetInvitations: (orgId: string, params: RequestParams = {}) =>
      this.request<IOrganizationInvitationLinkDto[], IApiUnauthorized>({
        path: `/organization/invitations/${orgId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name OrganizationControllerUninvite
     * @request POST:/organization/uninvite
     * @secure
     * @response `200` `void` Uninvite a user from a organization
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    organizationControllerUninvite: (data: IOrganizationUninviteDto, params: RequestParams = {}) =>
      this.request<void, IApiUnauthorized>({
        path: `/organization/uninvite`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name OrganizationControllerUserPermissions
     * @request POST:/organization/user/permissions
     * @secure
     * @response `200` `ICollaboratorPermissionResponseDto` Get a user permissions in a organization
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    organizationControllerUserPermissions: (data: ICollaboratorPermissionDto, params: RequestParams = {}) =>
      this.request<ICollaboratorPermissionResponseDto, IApiUnauthorized>({
        path: `/organization/user/permissions`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name OrganizationControllerGetOrganizationFeatures
     * @request GET:/organization/usage/features/{org_id}
     * @secure
     * @response `200` `IPlanFeaturesResponseDto` Get a organization features usage
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    organizationControllerGetOrganizationFeatures: (orgId: string, params: RequestParams = {}) =>
      this.request<IPlanFeaturesResponseDto, IApiUnauthorized>({
        path: `/organization/usage/features/${orgId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name OrganizationControllerGetOrganizationUsage
     * @request GET:/organization/usage/{org_id}
     * @secure
     * @response `200` `IOrganizationUsageResponse` Get a organization usage
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    organizationControllerGetOrganizationUsage: (orgId: string, params: RequestParams = {}) =>
      this.request<IOrganizationUsageResponse, IApiUnauthorized>({
        path: `/organization/usage/${orgId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name OrganizationControllerGetCollaborators
     * @request GET:/organization/collaborators/{org_id}
     * @secure
     * @response `200` `(IOrganizationCollaborator)[]` List all collaborators of a organization
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    organizationControllerGetCollaborators: (orgId: string, params: RequestParams = {}) =>
      this.request<IOrganizationCollaborator[], IApiUnauthorized>({
        path: `/organization/collaborators/${orgId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name OrganizationControllerUpdatePhoto
     * @request PUT:/organization/photo
     * @secure
     * @response `200` `IOrganization` Update the organization photo
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    organizationControllerUpdatePhoto: (data: IOrganizationPhotoUploadDto, params: RequestParams = {}) =>
      this.request<IOrganization, IApiUnauthorized>({
        path: `/organization/photo`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name OrganizationControllerGet
     * @request GET:/organization/{org_id}
     * @secure
     * @response `200` `IOrganization` Get a organization by its id
     * @response `401` `IApiUnauthorized` Unauthorized response
     */
    organizationControllerGet: (orgId: string, params: RequestParams = {}) =>
      this.request<IOrganization, IApiUnauthorized>({
        path: `/organization/${orgId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  personalization = {
    /**
     * No description
     *
     * @tags personalization
     * @name PersonalizationControllerTest
     * @summary Test endpoint
     * @request GET:/personalization/test
     * @response `200` `void` Return test message.
     */
    personalizationControllerTest: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/personalization/test`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags personalization
     * @name PersonalizationControllerUpsert
     * @summary Upsert personalization
     * @request POST:/personalization
     * @response `201` `IPersonalization` The personalization has been successfully upserted.
     */
    personalizationControllerUpsert: (data: IUpsertPersonalizationDto, params: RequestParams = {}) =>
      this.request<IPersonalization, any>({
        path: `/personalization`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags personalization
     * @name PersonalizationControllerFindAll
     * @summary Get all personalizations
     * @request GET:/personalization
     * @response `200` `void` Return all personalizations.
     */
    personalizationControllerFindAll: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/personalization`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags personalization
     * @name PersonalizationControllerFindOne
     * @summary Get personalization by per_id
     * @request GET:/personalization/{per_id}
     * @response `200` `void` Return the personalization with the specified per_id.
     */
    personalizationControllerFindOne: (perId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/personalization/${perId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags personalization
     * @name PersonalizationControllerRemove
     * @summary Delete personalization by per_id
     * @request DELETE:/personalization/{per_id}
     * @response `200` `void` The personalization has been successfully deleted.
     */
    personalizationControllerRemove: (perId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/personalization/${perId}`,
        method: "DELETE",
        ...params,
      }),
  };
  status = {
    /**
     * No description
     *
     * @tags status
     * @name StatusControllerFindAll
     * @summary Retrieve all status types
     * @request GET:/status/types
     * @response `200` `(IStatusType)[]` The status types have been successfully retrieved.
     */
    statusControllerFindAll: (params: RequestParams = {}) =>
      this.request<IStatusType[], any>({
        path: `/status/types`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags status
     * @name StatusControllerFindOne
     * @summary Retrieve a status by STS_ID
     * @request GET:/status/{sts_id}
     * @response `200` `IStatus` The status has been successfully retrieved.
     * @response `404` `void` Status not found
     */
    statusControllerFindOne: (stsId: string, params: RequestParams = {}) =>
      this.request<IStatus, void>({
        path: `/status/${stsId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags status
     * @name StatusControllerRemove
     * @summary Delete a status by STS_ID
     * @request DELETE:/status/{sts_id}
     * @response `200` `void` The status has been successfully deleted.
     * @response `404` `void` Status not found
     */
    statusControllerRemove: (stsId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/status/${stsId}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags status
     * @name StatusControllerFindByWorkspace
     * @summary Retrieve all statuses for a specific workspace
     * @request GET:/status/workspace/{wrk_id}
     * @response `200` `(IStatus)[]` The statuses have been successfully retrieved.
     * @response `404` `void` Workspace not found
     */
    statusControllerFindByWorkspace: (wrkId: string, params: RequestParams = {}) =>
      this.request<IStatus[], void>({
        path: `/status/workspace/${wrkId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags status
     * @name StatusControllerUpsert
     * @summary Upsert a status by ID
     * @request PUT:/status/upsert
     * @response `200` `IStatus` The status has been successfully upserted.
     * @response `400` `void` Invalid data
     */
    statusControllerUpsert: (data: IUpsertStatusDto, params: RequestParams = {}) =>
      this.request<IStatus, void>({
        path: `/status/upsert`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  approvalStage = {
    /**
     * No description
     *
     * @tags approvalStage
     * @name ApprovalStageControllerList
     * @request GET:/approval-stage/list
     * @secure
     * @response `200` `(IApprovalStage)[]` List all approval stages for a given approval
     * @response `401` `void` Unauthorized
     */
    approvalStageControllerList: (
      query: {
        /** ID of the approval to filter stages */
        apv_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<IApprovalStage[], void>({
        path: `/approval-stage/list`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags approvalStage
     * @name ApprovalStageControllerFind
     * @request GET:/approval-stage/{stg_id}
     * @secure
     * @response `200` `IApprovalStage` Get a single approval stage by its ID
     * @response `401` `void` Unauthorized
     */
    approvalStageControllerFind: (stgId: string, params: RequestParams = {}) =>
      this.request<IApprovalStage, void>({
        path: `/approval-stage/${stgId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags approvalStage
     * @name ApprovalStageControllerUpsert
     * @request POST:/approval-stage/upsert
     * @secure
     * @response `200` `IApprovalStage` Upsert an approval stage (Insert if not exists, update otherwise)
     * @response `401` `void` Unauthorized
     */
    approvalStageControllerUpsert: (data: IApprovalStageUpsertDto, params: RequestParams = {}) =>
      this.request<IApprovalStage, void>({
        path: `/approval-stage/upsert`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags approvalStage
     * @name ApprovalStageControllerDelete
     * @request DELETE:/approval-stage/delete
     * @secure
     * @response `200` `void` Delete an approval stage by its ID
     * @response `401` `void` Unauthorized
     */
    approvalStageControllerDelete: (data: IApprovalStageDeleteDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/approval-stage/delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags approvalStage
     * @name ApprovalStageControllerLink
     * @request POST:/approval-stage/link/customer
     * @secure
     * @response `200` `void` Link a customer to an approval flow stage
     * @response `401` `void` Unauthorized
     */
    approvalStageControllerLink: (data: IApprovalStageLinkCustomerDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/approval-stage/link/customer`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags approvalStage
     * @name ApprovalStageControllerUnlink
     * @request POST:/approval-stage/unlink/customer
     * @secure
     * @response `200` `void` Unlink a customer from an approval flow stage
     * @response `401` `void` Unauthorized
     */
    approvalStageControllerUnlink: (data: IApprovalStageUnlinkCustomerDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/approval-stage/unlink/customer`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  approvalFlow = {
    /**
     * No description
     *
     * @tags approvalFlow
     * @name ApprovalFlowControllerList
     * @request GET:/approval-flow/list
     * @secure
     * @response `200` `(IApprovalFlow)[]` List all approval flows for a given workspace
     * @response `401` `void` Unauthorized
     */
    approvalFlowControllerList: (
      query: {
        /** Workspace ID to filter approval flows */
        wrk_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<IApprovalFlow[], void>({
        path: `/approval-flow/list`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags approvalFlow
     * @name ApprovalFlowControllerFind
     * @request GET:/approval-flow/{flow_id}
     * @secure
     * @response `200` `IApprovalFlow` Get a single approval flow by its ID
     * @response `401` `void` Unauthorized
     */
    approvalFlowControllerFind: (flowId: string, params: RequestParams = {}) =>
      this.request<IApprovalFlow, void>({
        path: `/approval-flow/${flowId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags approvalFlow
     * @name ApprovalFlowControllerUpsert
     * @request POST:/approval-flow/upsert
     * @secure
     * @response `200` `IApprovalFlow` Upsert an approval flow (Insert if not exists, update otherwise)
     * @response `401` `void` Unauthorized
     */
    approvalFlowControllerUpsert: (data: IApprovalFlowUpsertDto, params: RequestParams = {}) =>
      this.request<IApprovalFlow, void>({
        path: `/approval-flow/upsert`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags approvalFlow
     * @name ApprovalFlowControllerDelete
     * @request DELETE:/approval-flow/delete
     * @secure
     * @response `200` `void` Delete an approval flow by its ID
     * @response `401` `void` Unauthorized
     */
    approvalFlowControllerDelete: (data: IApprovalFlowDeleteDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/approval-flow/delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  approvalFlowStage = {
    /**
     * No description
     *
     * @tags approvalFlowStage
     * @name ApprovalFlowStageControllerList
     * @request GET:/approval-flow-stage/list
     * @secure
     * @response `200` `(IApprovalFlowStage)[]` List all approval flow stages for a given workspace
     * @response `401` `void` Unauthorized
     */
    approvalFlowStageControllerList: (params: RequestParams = {}) =>
      this.request<IApprovalFlowStage[], void>({
        path: `/approval-flow-stage/list`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags approvalFlowStage
     * @name ApprovalFlowStageControllerFind
     * @request GET:/approval-flow-stage/{afs_id}
     * @secure
     * @response `200` `IApprovalFlowStage` Get a single approval flow stage by its ID
     * @response `401` `void` Unauthorized
     */
    approvalFlowStageControllerFind: (afsId: string, params: RequestParams = {}) =>
      this.request<IApprovalFlowStage, void>({
        path: `/approval-flow-stage/${afsId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags approvalFlowStage
     * @name ApprovalFlowStageControllerUpsert
     * @request POST:/approval-flow-stage/upsert
     * @secure
     * @response `200` `IApprovalFlowStage` Upsert an approval flow stage (Insert if not exists, update otherwise)
     * @response `401` `void` Unauthorized
     */
    approvalFlowStageControllerUpsert: (data: IApprovalFlowStageUpsertDto, params: RequestParams = {}) =>
      this.request<IApprovalFlowStage, void>({
        path: `/approval-flow-stage/upsert`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags approvalFlowStage
     * @name ApprovalFlowStageControllerDelete
     * @request DELETE:/approval-flow-stage/delete
     * @secure
     * @response `200` `void` Delete an approval flow stage by its ID
     * @response `401` `void` Unauthorized
     */
    approvalFlowStageControllerDelete: (data: IApprovalFlowStageDeleteDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/approval-flow-stage/delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags approvalFlowStage
     * @name ApprovalFlowStageControllerLink
     * @request POST:/approval-flow-stage/link/customer
     * @secure
     * @response `200` `void` Link a customer to an approval flow stage
     * @response `401` `void` Unauthorized
     */
    approvalFlowStageControllerLink: (data: IApprovalFlowStageLinkCustomerDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/approval-flow-stage/link/customer`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags approvalFlowStage
     * @name ApprovalFlowStageControllerUnlink
     * @request POST:/approval-flow-stage/unlink/customer
     * @secure
     * @response `200` `void` Unlink a customer from an approval flow stage
     * @response `401` `void` Unauthorized
     */
    approvalFlowStageControllerUnlink: (data: IApprovalFlowStageUnlinkCustomerDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/approval-flow-stage/unlink/customer`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  customer = {
    /**
     * No description
     *
     * @tags customer
     * @name CustomerControllerList
     * @request GET:/customer/list
     * @secure
     * @response `200` `(ICustomer)[]` List all customers for a given workspace
     * @response `401` `void` Unauthorized
     */
    customerControllerList: (
      query: {
        /**
         * Unique identifier of the workspace.
         * @example "2f143673-f86c-4f84-ae97-9faf7cd78921"
         */
        wrk_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ICustomer[], void>({
        path: `/customer/list`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags customer
     * @name CustomerControllerFind
     * @request GET:/customer/{cust_id}
     * @secure
     * @response `200` `ICustomer` Get a single customer by its ID
     * @response `401` `void` Unauthorized
     */
    customerControllerFind: (custId: string, params: RequestParams = {}) =>
      this.request<ICustomer, void>({
        path: `/customer/${custId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags customer
     * @name CustomerControllerUpsert
     * @request POST:/customer/upsert
     * @secure
     * @response `200` `ICustomer` Upsert a customer (Insert if not exists, update otherwise)
     * @response `401` `void` Unauthorized
     */
    customerControllerUpsert: (data: ICustomerUpsertDto, params: RequestParams = {}) =>
      this.request<ICustomer, void>({
        path: `/customer/upsert`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags customer
     * @name CustomerControllerDelete
     * @request DELETE:/customer/delete
     * @secure
     * @response `200` `void` Delete a customer by its ID
     * @response `401` `void` Unauthorized
     */
    customerControllerDelete: (data: ICustomerDeleteDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/customer/delete`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags customer
     * @name CustomerControllerUpdatePhoto
     * @request PUT:/customer/photo
     * @secure
     * @response `200` `ICustomer` Update the customer photo
     * @response `401` `void` Unauthorized
     */
    customerControllerUpdatePhoto: (data: ICustomerPhotoUploadDto, params: RequestParams = {}) =>
      this.request<ICustomer, void>({
        path: `/customer/photo`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  approvalItem = {
    /**
     * No description
     *
     * @tags approvalItem
     * @name ApprovalItemControllerList
     * @request GET:/approval-item/list
     * @secure
     * @response `200` `(IApprovalItem)[]` List all approval items
     * @response `401` `void` Unauthorized response
     */
    approvalItemControllerList: (
      query?: {
        /** Filter by item description */
        item_description?: string;
        /** Filter by approval ID */
        apv_id?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<IApprovalItem[], void>({
        path: `/approval-item/list`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags approvalItem
     * @name ApprovalItemControllerUpsert
     * @request POST:/approval-item/upsert
     * @secure
     * @response `200` `IApprovalItem` Upsert an approval item (Insert if not exists, update otherwise)
     * @response `401` `void` Unauthorized response
     */
    approvalItemControllerUpsert: (data: IApprovalItemUpsertDto, params: RequestParams = {}) =>
      this.request<IApprovalItem, void>({
        path: `/approval-item/upsert`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags approvalItem
     * @name ApprovalItemControllerDeleteItemAsset
     * @request POST:/approval-item/delete/asset
     * @secure
     * @response `200` `void` Delete an approval item asset by its id
     * @response `401` `void` Unauthorized response
     */
    approvalItemControllerDeleteItemAsset: (data: IApprovalItemAssetDeleteDto, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/approval-item/delete/asset`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags approvalItem
     * @name ApprovalItemControllerDelete
     * @request POST:/approval-item/delete
     * @secure
     * @response `200` `IApprovalItem` Delete an approval item by its id
     * @response `401` `void` Unauthorized response
     */
    approvalItemControllerDelete: (data: IApprovalItemDeleteDto, params: RequestParams = {}) =>
      this.request<IApprovalItem, void>({
        path: `/approval-item/delete`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags approvalItem
     * @name ApprovalItemControllerGet
     * @request GET:/approval-item/{item_id}
     * @secure
     * @response `200` `IApprovalItem` Get an approval item by its id
     * @response `401` `void` Unauthorized response
     */
    approvalItemControllerGet: (itemId: string, params: RequestParams = {}) =>
      this.request<IApprovalItem, void>({
        path: `/approval-item/${itemId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  dashboard = {
    /**
     * No description
     *
     * @tags Dashboard
     * @name DashboardControllerGetDashboardReport
     * @request GET:/dashboard/report
     * @response `200` `IDashboardResponseDto` Dados do dashboard retornados com sucesso
     */
    dashboardControllerGetDashboardReport: (
      query: {
        /**
         * Data de início para filtrar as aprovações
         * @example "2024-01-01"
         */
        startDate: string;
        /**
         * Data de término para filtrar as aprovações
         * @example "2024-01-31"
         */
        endDate: string;
        /**
         * ID do workspace para filtrar as aprovações
         * @example "123e4567-e89b-12d3-a456-426614174000"
         */
        wrk_id?: string;
        /**
         * ID da organização para filtrar as aprovações
         * @example "cd034bcf-ece7-4537-95d8-c87e9142766a"
         */
        org_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<IDashboardResponseDto, any>({
        path: `/dashboard/report`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  approvalItemRevision = {
    /**
     * No description
     *
     * @tags approvalItemRevision
     * @name ApprovalItemRevisionControllerCreateComment
     * @request POST:/approvalItemRevision/comment
     * @secure
     * @response `200` `IApprovalItemRevisionCommentResponseDto` Create a new comment
     * @response `401` `void` Unauthorized response
     */
    approvalItemRevisionControllerCreateComment: (
      data: IApprovalItemRevisionCommentRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<IApprovalItemRevisionCommentResponseDto, void>({
        path: `/approvalItemRevision/comment`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  publicApi = {
    /**
     * @description Retrieve the available plans for a given user based on their unique ID (usu_id).
     *
     * @name ApiControllerGetAvailablePlans
     * @summary Get Available Plans
     * @request GET:/public-api/plans
     * @response `200` `IIPublicPlanAvailableDto` Successfully retrieved available plans
     * @response `400` `void` Invalid request parameters
     */
    apiControllerGetAvailablePlans: (params: RequestParams = {}) =>
      this.request<IIPublicPlanAvailableDto, void>({
        path: `/public-api/plans`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieve the status of the approval based on the asc_id (Approval Stage Customer ID).
     *
     * @name ApiControllerGetApprovalStatus
     * @summary Get status single approval basead on asc_id
     * @request POST:/public-api/approval/status
     * @response `200` `IStatusApprovalResponseDto` Successfully retrieved approval
     * @response `400` `void` Invalid request parameters
     */
    apiControllerGetApprovalStatus: (data: IStatusApprovalRequestDto, params: RequestParams = {}) =>
      this.request<IStatusApprovalResponseDto, void>({
        path: `/public-api/approval/status`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieve the approval based on the asc_id (Approval Stage Customer ID).
     *
     * @name ApiControllerGetApproval
     * @summary Get one single approval basead on asc_id
     * @request POST:/public-api/approval
     * @response `200` `ICheckApprovalResponseDto` Successfully retrieved approval
     * @response `400` `void` Invalid request parameters
     */
    apiControllerGetApproval: (data: ICheckApprovalDto, params: RequestParams = {}) =>
      this.request<ICheckApprovalResponseDto, void>({
        path: `/public-api/approval`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Finish the approval process.
     *
     * @name ApiControllerFinishApproval
     * @summary Finish approval
     * @request POST:/public-api/finish-approval
     * @response `200` `IApprovalStageCustomer` Successfully finished approval
     * @response `400` `void` Invalid request parameters
     */
    apiControllerFinishApproval: (data: IApprovalStageCustomer, params: RequestParams = {}) =>
      this.request<IApprovalStageCustomer, void>({
        path: `/public-api/finish-approval`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
