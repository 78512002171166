import { Box, IconButton, Stack, Typography } from "@mui/material";

export type IStatusIcon = "success" | "error" | "warning";

export const IconOverlay = ({
  icon,
  size = 75,
  children,
}: {
  children: React.ReactNode;
  icon: IStatusIcon;
  size?: number;
}) => {
  return (
    <Box position="relative" display="inline-block" sx={{ cursor: "pointer" }}>
      <Stack direction="column" alignItems="center" spacing={1}>
        {/* Avatar */}
        <Box position="relative" display="inline-block">
          {children}

          {/* Check icon (bottom/right) */}
          {icon && (
            <IconButton
              size="small"
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
              }}
            >
              {/* <CheckCircle
                fontSize="medium"
                color="success"
              /> */}
              {/* <img
                src={`/assets/icons/icon-${icon}.png`}
                width={size / 3}
                height={size / 3}
              /> */}
              <RenderIcon icon={icon} size={size / 3} />
            </IconButton>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export const RenderIcon = ({
  icon,
  size = 75,
}: {
  icon: IStatusIcon;
  size?: number;
}) => {
  return (
    <img src={`/assets/icons/icon-${icon}.png`} width={size} height={size} />
  );
};

export default IconOverlay;
