import React from "react";
import { approvalSlice } from "../features/approvals/approvalsSlice";
import { useAppSelector } from "../hooks";
import { Stack } from "@mui/system";
import { Container, Typography, useTheme } from "@mui/material";
import { TopNav } from "../components/top-nav";

interface IStatusAllApprovedGuard {
  children?: React.ReactNode;
}

export const StatusAllApprovedGuard: React.FC<IStatusAllApprovedGuard> = ({
  children,
}) => {
  const theme = useTheme();
  const isApproved = useAppSelector(approvalSlice.selectors.isApproved);

  if (!isApproved) {
    return <>{children}</>;
  }

  return (
    <Stack
      flex={1}
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      spacing={4}
      bgcolor={theme.palette.background.paper}
    >
      <TopNav />
      <img
        alt="Success"
        src={`/assets/icons/icon-success.png`}
        width={50}
        height={50}
      />
      <Container>
        <Typography variant="h4" gutterBottom textAlign="center">
          Aprovação Concluída!
        </Typography>
        <Typography variant="body1" textAlign="center">
          Todas as pendências foram aprovadas.
          <br /> Você concluiu esta etapa!
        </Typography>
      </Container>
    </Stack>
  );
};
