import {
  AppBar,
  Toolbar,
  Avatar,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
  IconButton,
  Tooltip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { approvalSlice } from "../features/approvals/approvalsSlice";
import { useAppSelector } from "../hooks";
import { getInitials } from "../utils/get-initials";
import { Business } from "@mui/icons-material";
import { Progress } from "./progress";

export const MobileHeader: React.FC = () => {
  const theme = useTheme();
  const { total } = useAppSelector(approvalSlice.selectors.selectCount);
  const approval = useAppSelector(approvalSlice.selectors.selectApproval);
  const organization = useAppSelector(
    approvalSlice.selectors.selectOrganization
  );
  const workspace = useAppSelector(approvalSlice.selectors.selectWorkspace);

  return (
    <AppBar
      position="fixed"
      style={{
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Toolbar>
        <Tooltip title={workspace?.wrk_name} arrow>
          <Avatar
            src={workspace?.wrk_photo?.ast_url || ""}
            sx={{ width: 40, height: 40, ml: 0.5 }}
          >
            {workspace?.wrk_name ? (
              getInitials(workspace.wrk_name)
            ) : (
              <Business />
            )}
          </Avatar>
        </Tooltip>

        <Stack direction="column" sx={{ ml: 2, flexGrow: 1 }}>
          <Typography variant="body2" noWrap color={theme.palette.text.primary}>
            {approval?.apv_name || "Aprovação"}
          </Typography>
          <Typography
            variant="caption"
            color={theme.palette.text.secondary}
            noWrap
          >
            Total: {total} itens
          </Typography>
        </Stack>
        <Tooltip title={organization?.org_name} arrow>
          <Avatar
            src={organization?.org_photo.ast_url || ""}
            sx={{ width: 40, height: 40, ml: 0.5 }}
          >
            {organization?.org_name ? (
              getInitials(organization.org_name)
            ) : (
              <Business />
            )}
          </Avatar>
        </Tooltip>
      </Toolbar>
      <Progress />
    </AppBar>
  );
};
