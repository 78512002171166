export enum Mimetype {
  Image = 'image',
  Video = 'video',
  Pdf = 'PDF',
  Unknown = 'unknown',
}

export function getMimetype(str: string | null | undefined): Mimetype {
  if (!str) {
    return Mimetype.Unknown;
  }

  if (str.includes('video')) return Mimetype.Video;
  if (str.includes('image')) return Mimetype.Image;
  if (str.includes('pdf')) return Mimetype.Pdf;

  return Mimetype.Unknown;
}
